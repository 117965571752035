import Header, { LandingHeaderLinks } from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import '../legal.scss';

const Terms = () => (
  <main className="gotok-pay">
    <Header>
      <LandingHeaderLinks />
    </Header>

    <section className="c-legal c-legal--terms">
      <article className="c-legal__hero">
        <div className="l-wrapper">
          <div className="l-row">
            <div className="l-col-9 l-flex-column">
              <h5>Terms & Conditions</h5>
              <h3>The following document outlines Gotok's Terms of Service</h3>
              <h6>Effective Date: November 30, 2022</h6>
            </div>
          </div>
        </div>
      </article>

      <article className="c-legal__content">
        <div className="l-wrapper">
          <div className="l-row">
            <div className="l-col-7">
              <div
                className="c-legal__manifest"
                style={{ margin: '4.8rem 0 2.0rem 0', opacity: '.75' }}
              >
                <p>
                  The following Terms and Conditions (these “Terms”) apply to
                  any person that registers or utilises and/or opens a account
                  through Gotok (mobile applications, website, or APIs). These
                  terms constitute a legally binding agreement between you and
                  Gotok Technologies Limited, a private limited liability
                  company incorporated under the Laws of the Federal Republic of
                  Nigeria.
                </p>
              </div>

              <h4>Interpretation and Definitions</h4>

              <div className="c-legal__text">
                <h2>Interpretation</h2>
                <p>
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Definitions</h2>
                <p>For the purposes of these Terms and Conditions:</p>
                <ul>
                  <li>
                    Cryptocurrency: Refers to any digital asset designed to work
                    as a medium of exchange on blockchain and accepted in
                    payment throughout the Services.
                  </li>
                  <li>
                    Gotok: Refers to Gotok Technologies Limited, as well as all
                    its subsidiaries, employees responsible for providing the
                    Services.
                  </li>
                  <li>
                    Company (referred to as either “the Company”, “We”, “Us” or
                    “Our” in this Agreement) refers to Gotok Technologies
                    Limited.
                  </li>
                  <li>
                    Platform: Refers to the web and mobile app "Gotok" and the
                    including including any subdomains thereof, and any platform
                    where the referred to in the Terms of Use of Use are of Use
                    are available.
                  </li>
                  <li>
                    User(s): Refers to the natural or legal person registered on
                    the Platform and holding a Wallet
                  </li>
                  <li>
                    Service(s): Refers to every service accessible on the
                    Platform for an User holding a Wallet.
                  </li>
                  <li>
                    Terms and Conditions (also referred as “Terms”) mean these
                    Terms and Conditions that form the entire agreement between
                    You and the Company regarding the use of the Service. This
                    Terms and Conditions agreement has been created with the
                    help of the Terms and Conditions Generator.
                  </li>
                  <li>
                    Third-Party: Refers to any person, natural or legal, apart
                    from Gotok and Users.
                  </li>
                  <li>
                    Transactions: Means all operations of receiving and sending
                    Cryptocurrencies from the Wallet provided on the Platform.
                  </li>
                  <li>
                    Wallet: Refers to the non-custodial wallet furnished by
                    Gotok to each User, the latter having sole control private
                    keys enabling access to his/her cryptocurrencies.
                  </li>
                </ul>
              </div>

              <div className="c-legal__text">
                <h2>Acknowledgment</h2>
                <p>
                  These are the Terms and Conditions governing the use of this
                  Service and the agreement that operates between You and the
                  Company. These Terms and Conditions set out the rights and
                  obligations of all users regarding the use of the Service.
                </p>
                <p>
                  Your access to and use of the Service is conditioned on Your
                  acceptance of and compliance with these Terms and Conditions.
                  These Terms and Conditions apply to all visitors, users and
                  others who access or use the Service.
                </p>
                <p>
                  By accessing or using the Service You agree to be bound by
                  these Terms and Conditions. If You disagree with any part of
                  these Terms and Conditions, then You may not access the
                  Service.
                </p>
                <p>
                  You represent that you are over the age of 18. The Company
                  does not permit those under 18 to use the Service.
                </p>
                <p>
                  Your access to and use of the Service is also conditioned on
                  Your acceptance of and compliance with the Privacy Policy of
                  the Company. Our Privacy Policy describes Our policies and
                  procedures on the collection, use and disclosure of Your
                  personal information when You use the Application or the
                  Website and tells You about Your privacy rights and how the
                  law protects You. Please read Our Privacy Policy carefully
                  before using Our Service.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Amendment of Terms</h2>
                <p>
                  We may update our terms from time to time. We reserve the
                  right and sole discretion to make changes to these terms at
                  any time. It is your responsibility to check this service
                  periodically for changes. Changes to these terms are effective
                  when they are uploaded to our service.
                </p>
                <p>
                  We will do our best to notify you of any amendments to these
                  Terms that we consider likely to affect your rights and
                  obligations materially. Any such notice will be posted on the
                  Gotok website or sent by email to the email address associated
                  with your Gotok Account.
                </p>
                <p>
                  Following any amendments, continued use of our service means
                  acceptance and agreement to the changes. If you do not agree
                  with any amendments made to these Terms at any time in future,
                  you must stop using all Gotok services with immediate effect.
                  We will send you newsletters, service announcements, relevant
                  information as you use our services. You may wish to may wish
                  to unsubscribe from our mailing list.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Eligibility</h2>
                <p>
                  By registering to use Gotok, you represent and warrant that:
                </p>
                <ul>
                  <li>
                    As an individual, you are at least 18 or the legal age to
                    form a binding contract under applicable laws.
                  </li>
                  <li>
                    As an individual, legal person, or other organisation, you
                    have the legal capacity and sufficient authorisations to
                    enter these Terms.
                  </li>
                  <li>
                    You will not open, or attempt to open, an account on Gotok
                    under any name except your own; or use Gotok to carry out
                    transactions on behalf of a third party.
                  </li>
                  <li>
                    Your use of Gotok's Services will not violate any laws and
                    regulations applicable to you, including but not limited to
                    anti-money laundering, anti-corruption, and
                    counter-terrorist financing
                  </li>
                </ul>
              </div>

              <div className="c-legal__text">
                <h2>Using this Service</h2>
                <p>Gotok is an online Wallet that enables Users to:</p>
                <p>
                  Create a Wallet that interacts with various blockchains and
                  enables each User to monitor its balances of assets and to
                  execute Transactions, i.e. receive, send and store
                  Cryptocurrencies;
                </p>
                <p>
                  Share a dedicated link allowing sending Cryptocurrencies to
                  users who do not have a Wallet. In this event, such users are
                  invited to create a Wallet on the Platform
                </p>
                <p>Display the rate of Cryptocurrencies in fiat currency</p>
                <p>
                  Backup and restore private and public keys under the sole User
                  responsibility
                </p>
                <p>Create and manage virtual credit card</p>
                <p>
                  Using this service includes but is not limited to providing
                  information, engaging in digital currency transactions,
                  including conversion of supported digital currency, making
                  submissions and participating in offers. You undertake to use
                  this service only as permitted by law. You agree not to
                  undertake the following:
                </p>
                <ul>
                  <li>
                    Reuse, exploit, and misuse any portion of this service.
                  </li>
                  <li>
                    Make any alterations that may interfere with the service
                    provided.
                  </li>
                  <li>
                    Use the service to damage the server and impair the use of
                    the service by other users. Use your Account to carry out
                    transactions on behalf of a third party or open an account
                    using the name and details of a third party.
                  </li>
                  <li>
                    Obtain unauthorised access to a third party’s account or the
                    Gotok database.
                  </li>
                  <li>
                    Illegal and prohibited activity as stated in the criminal
                    code of Nigeria and violation of any law, statute or
                    precedent in Nigeria
                  </li>
                </ul>
              </div>

              <div className="c-legal__text">
                <h2>Pre-contractual information</h2>
                <p>
                  By using the Platform, Users acknowledge to be aware of the
                  following information:
                </p>
                <p>
                  Cryptocurrencies and custody services rely on emerging
                  technologies presenting risk of failure, bug or malfunction;
                </p>
                <p>
                  Please note that the risks involved in trading or holding
                  Digital Currencies can be substantial. Digital Currency
                  Services are not currently regulated by the Central Bank of
                  Nigeria or any other regulator. Therefore, carefully consider
                  whether trading or holding Digital Currencies is suitable for
                  your risk appetite.
                </p>
                <p>
                  Before using the platforms, you should ensure that you
                  understand the risks involved in Digital digital currency
                  markets can be volatile, and prices can fluctuate
                  significantly, which could result in sudden and significant
                  increases or decreases in the value of your your assets.
                </p>
                <p>
                  You should carefully assess whether your financial situation
                  and risk tolerance are suitable for Digital Currencies. You
                  are solely responsible for any decision to send, convert or
                  otherwise hold or deal with Digital Currencies. Gotok does
                  not, and will not, offer you any financial advice in
                  connection with your use of or transactions conducted.
                </p>
                <p>
                  You are solely responsible for the decision to use our service
                  to deal with our supported digital currency and accept the
                  risk that underlying software protocols relating to the
                  supported digital currency you store in your Digital Currency
                  Balance may change
                </p>
                <p>
                  You indemnify Gotok, it's operating entities or any other
                  affiliates (including their respective directors, members,
                  employees or agents), to the maximum extent permitted by law,
                  against any loss, liability action, cost, claim, damages,
                  proceedings or expense that you may suffer, directly or
                  indirectly, in connection with your Gotok account (without
                  limitation) the loss of digital currencies deposited deposited
                  (whether in whole or in part).
                </p>
                <p>
                  You accordingly hereby waive any claim that you may have
                  against Gotok arising therefrom (including in respect of claim
                  for direct, indirect, consequential or pecuniary damages). You
                  understand and agree that should a counter-party become
                  insolvent or otherwise deliver any deposited amount; this may
                  impact your investment.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Account Information, Verification and Access</h2>
                <p>
                  To use our service, you must open and create your personalised
                  Account. You confirm that all information provided is to open
                  the account is accurate, and you agree to update your
                  information once a change occurs. You will provide security
                  for transactions carried out through your Account by ensuring
                  sufficient local or digital currency in your account or at
                  least the minimum closing amount
                </p>
                <p>
                  In furtherance of our Anti-Money Laundering & Combating the
                  Financing of Terrorism (AML/CFT) Policy, you agree to provide
                  us with requisite information by filling out the Know Your
                  Client (KYC form). Once the verification procedure has been
                  completed and the KYC form has been filled out, users are
                  granted access to our Service. Kindly visit this{' '}
                  <a href="https://gotok.app/aml">link</a> for more information
                  on our AML/CFT policy.
                </p>
                <p>
                  The required information will include but is not limited to
                  the following: name, address, telephone number, e-mail
                  address, date of birth, tax identification number (TIN), valid
                  identification card number, bank verification number and bank
                  account information (such as the name of the bank, the account
                  type, sort code number, and account number).
                </p>
                <p>
                  We may be required to submit additional information on you,
                  your business, provide records to regulators or require you to
                  meet meet with our staff in certain circumstances. This
                  process is enhanced due diligence (“EDD”). We reserve the
                  right to charge you for the EDD, and we will notify you of the
                  same where appropriate. While we verify your identity and
                  carry out the EDD, you agree that there may be delays
                  accessing your account and carrying out transactions. Once the
                  process is complete, your account will run seamlessly.
                </p>
                <p>
                  As part of the Identity Verification and EDD procedures, we
                  are required to retain certain information even after we have
                  closed and suspended your account, or you have terminated your
                  account. See our privacy and AML/CFT policy for more
                  information. To protect your Account, which comprises your
                  balances amongst others, keep your password and pin
                  confidential and secure. You are responsible for any activity
                  carried out using our service. Proceeds realised from the sale
                  of your supported digital currency(s) will be credited to your
                  Balances, less any applicable fees and taxes and interest.
                </p>
                <p>Gotok reserves the right to, at any time:</p>
                <ul>
                  <li>
                    Restrict or suspend your account on Gotok when we, in our
                    sole discretion, consider it necessary to carry out further
                    Identity Verification and/or EDD; or
                  </li>
                  <li>
                    Terminate your account on Gotok if you provide, or we
                    suspect you have provided, false information or refuse to
                    provide the information we require for Identity Verification
                    and/or EDD.
                  </li>
                </ul>
              </div>
              <div className="c-legal__text">
                <h2>Retention of information</h2>
                <p>
                  Gotok is mandated to retain certain information and
                  documentation obtained as part of the Identity Verification
                  and EDD procedures. These requirements apply even when you
                  have terminated your relationship with Gotok. We reserve the
                  right to keep such information and documentation for the
                  required period. You accept and agree that information and
                  documentation you provide to Gotok may be retained by us,
                  including following the closure of your Gotok Account.
                </p>
              </div>
              <div className="c-legal__text">
                <h2>Balances</h2>
                <p>
                  The Gotok card balances services allow you to send the
                  supported fiat currency and/or Digital Currencies to and
                  request, request, receive and store supported Digital
                  Currencies from parties according to instructions you provide
                  on Gotok.
                </p>
                <p>
                  Please note that the Balances is not a deposit account.
                  However, Gotok will hold funds received in a designated
                  safekeeping account with a reputable financial institution.
                  E-money held in Balances will not earn any interest. The
                  Supported Digital Currencies by Gotok may change from time.
                  All transactions must be carried out using the Digital
                  Currencies supported by Gotok.
                </p>
                <p>
                  We retain the right to suspend the provision of our services
                  to you:
                </p>
                <ul>
                  <li>
                    If you do not comply with our terms of use, additional
                    police or we discover and are investigating suspected
                    misconduct.
                  </li>
                  <li>
                    If you receive any digital currency that digital currency is
                    not a Gotok supported Digital Currency.
                  </li>
                  <li>
                    If the digital currency is sent to an address associated
                    with your Balances, the address is associated with another
                    digital currency.
                  </li>
                </ul>
                <p>
                  After completing the verification procedures, you may convert
                  to supported Digital Currencies directly from Gotok. A payment
                  processing fee applies to all conversions. Gotok uses
                  third-party payment processors to process any Fiat Currency
                  payment between you and Gotok. You will view your balances and
                  your conversion history using the dashboard service, which
                  will show the key account information. This information will
                  include the amount and currency of each conversion, reference
                  to identify the payer or payee, breakdown of fees charged, the
                  rate of exchange, amount after exchange and other details of
                  the exchange.
                </p>
                <h3>
                  By using the platform, you acknowledge and agree to the
                  following:
                </h3>
                <ul>
                  <li>
                    Gotok is not responsible for the operation of the underlying
                    protocols and that Gotok makes no guarantee of their
                    functionality, security, or availability; and
                  </li>
                  <li>
                    The underlying protocols are subject to sudden changes in
                    operating rules that may materially affect the value,
                    function, and/or even the name of the Digital Currency you
                    store on the platform.
                  </li>
                </ul>
                <h3>
                  Fiat currency deposited into your Balances is solely for:
                </h3>
                <ul>
                  <li>The conversion to supported Digital Currencies</li>
                  <li>Transfer from one balance to another.</li>
                  <li>Funding of your virtual credit card.</li>
                  <li>
                    We may also allow you to purchase specific goods and/or
                    services from selected third parties using your balance in
                    certain countries. You accept and agree that any such
                    purchase will be subject to these Terms. Proceeds from the
                    conversion of supported Digital Currencies will be credited
                    to the Balances, less applicable fees.
                  </li>
                </ul>
                <p>
                  You cannot cancel, reverse, or change any transaction marked
                  as complete or pending. If your payment is not successful or
                  if your payment method has insufficient funds, you authorise
                  Gotok to cancel the transaction at its sole discretion. are
                  responsible for maintaining an adequate balance and/or
                  sufficient credit limits to avoid overdraft, insufficient
                  funds, or similar fees charged by your payment provider (i.e.,
                  your bank, external wallet).
                </p>
              </div>
              <div className="c-legal__text">
                <h2>Transaction Instructions</h2>
                <p>
                  Gotok processes all transactions according to the instructions
                  received from its users, and we do not guarantee the identity
                  of any user, receiver, requester or other parties. You should
                  verify all transaction information before submitting
                  instructions to Gotok. A transaction is not complete while it
                  is pending. Funds associated with transactions in a pending
                  state will be designated accordingly and will not be included
                  in your Gotok card's available balance or be available to
                  conduct transactions. Gotok may charge network fees (miner
                  fees) to process a Digital Currency transaction on your
                  behalf. Gotok will calculate the network fee at its
                  discretion, although Gotok will always notify you of the
                  network fee at or before the time you authorise the
                  transaction.
                </p>
                <p>
                  Gotok has no control over, or liability for, the delivery,
                  quality, safety, legality or any other aspect of any goods or
                  services that you may purchase or sell to or from a third
                  party (including other users of Gotok Services). not own or
                  control the underlying software protocols that govern Digital
                  Currencies' operation supported on our on our platform. In
                  general, the underlying protocols are open source, and anyone
                  can use, copy, modify, and distribute them
                </p>
                <p>
                  Gotok will process Balances Transactions according to your
                  instructions. You accept and agree that Gotok does not:
                </p>
                <ul>
                  <li>
                    Guarantee the identity of any user, receiver, or another
                    party to a Balances Transaction. You are solely responsible
                    for ensuring all transaction details are correct, and you
                    should carefully verify all transaction information before
                    submitting transaction instructions to Gotok.
                  </li>
                  <li>
                    Have any control over, or any liability concerning, the
                    delivery, quality or any other aspect of any goods or
                    services that you may buy from or sell to any third party.
                    Balances shall not be responsible for and will take no
                    action concerning ensuring that any buyer or seller you
                    transact with using your Balances completes the relevant
                    transaction or has the requisite authority to do so.
                  </li>
                </ul>
                <p>
                  You may receive Supported Digital Currencies into your
                  Balances by providing the sender with a receive address
                  generated in your Balances. Your Balances will only be
                  credited with Supported Cryptocurrency sent to a receive
                  address generated through your Balances and associated with
                  that Supported Cryptocurrency.
                </p>
                <p>
                  If you know, suspect, or should reasonably know or suspect
                  that any Local Currency or Digital Currency has been credited
                  to your Balances (or to any bank account) in error, you must
                  immediately notify Balances of the error by submitting a
                  ticket through the Help Centre. You accept and agree that you
                  have no claim or entitlement to any Local Currency or Digital
                  Currency received in error and must immediately return such
                  funds in accordance with the instructions received from
                  Balances.
                </p>
              </div>
              <div className="c-legal__text">
                <h2>Withdrawals</h2>
                <p>
                  You may withdraw funds from your Card Balance to an external
                  wallet or third party account we may support in the future (a
                  “Withdrawal”). Gotok will process Withdrawals following your
                  instructions. You are solely responsible for ensuring that any
                  Withdrawal instructions provided to Gotok, including the
                  relevant account details, are complete and correct. You accept
                  and agree that under no circumstances will Gotok be under any
                  obligation to reverse or amend any Withdrawal.
                </p>
                <p>
                  Gotok will make all reasonable efforts to process Withdrawals
                  each business day, following the Deposit and Withdrawal
                  Schedule on the platform. You accept and agree that guarantee
                  concerning the Withdrawal processing period.‍
                </p>
                <p>
                  You agree that we have not provided any guarantee in relation
                  to the withdrawal processing period. We reserve the right to
                  refuse any transaction if we suspect that such action is in
                  furtherance of illegal and fraudulent activity or that such
                  action will expose Gotok to risk. We shall not be liable any
                  delay or non-completion of a transaction.
                </p>
              </div>
              <div className="c-legal__text">
                <h2>Support</h2>
                <p>
                  Support is available at this email address: help@gotok.app.
                </p>
                <p>
                  The Service undertakes to respond as quickly as possible,
                  depending on the number of requests in process.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Wallet</h2>
                <h3>Use, creation and access</h3>
                <p>
                  Any User can create a Wallet or use an existing Wallet on the
                  Service by :
                </p>
                <p>
                  The Service provides each User with a unique Wallet to ensure
                  the conservation and the use of Cryptocurrencies within the
                  Service.
                </p>
                <ul>
                  <li>installing the application ;</li>
                  <li>
                    saving carefully a recovery phrase provided by the app. This
                    secret recovery phrase allows the user to recover a Wallet
                    in case of stolen device for example ;
                  </li>
                  <li>accepting the Terms.</li>
                </ul>
                <p>
                  Users are free to use the Wallet to execute Transactions from
                  the Platform. He/She may credit or debit the Wallet with
                  his/her own Cryptocurrencies at any time.
                </p>
                <p>
                  It is the User's responsibility to take all measures to secure
                  access to his/her Wallet. Gotok shall not be held liable for
                  the loss or theft of the secret recovery phrase by the User,
                  or by the resulting Wallet impersonation or access loss.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Deletion</h2>
                <p>
                  Gotok reserves the right to suspend or delete a Wallet in
                  violation of these Terms of Use, without prior notice or
                  compensation.
                </p>
                <p>
                  The User may, at any time, request the deletion of his/her
                  Wallet under the conditions set out by the Platform.
                </p>
                <p>
                  The Service shall not be held liable, in any circumstances,
                  for any loss arising from a Wallet deletion.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Account Security</h2>
                <p>
                  Gotok takes security very seriously, and we take internal
                  measures to protect your Gotok Account. However, you are
                  solely responsible for:
                </p>
                <ul>
                  <li>
                    Maintaining adequate security and control over your Gotok
                    Account sign-in details, including but not limited to any
                    passwords, personal identification numbers (PINs), API keys,
                    or any other codes associated with your Gotok Account.
                  </li>
                  <li>
                    Keeping your contact details up to date so that you can
                    receive any notices or alerts we may send to you in relation
                    to security (see Notices).
                  </li>
                  <li>
                    Maintaining security and control over the email mailbox,
                    phone number and two-factor authentication applications or
                    devices associated with your Gotok Account.
                  </li>
                </ul>
                <p>
                  We shall not be liable for any damage or malfunctions caused
                  by viruses, spyware, or other malware that may affect your
                  device or other equipment. We strongly advise the use of
                  reputable virus screening and prevention software to be
                  updated regularly to combat such viruses. Please note the
                  following:
                </p>
                <ul>
                  <li>
                    SMS and email services are vulnerable, and care should be
                    taken when reviewing messages purporting to originate from
                    us.
                  </li>
                  <li>
                    Enabling any additional security features available to you,
                    including the activation of two-factor authentication on
                    your Gotok account
                  </li>
                  <li>We will never seek access to your device or account.</li>
                  <li>
                    We will not request your personal identification number
                    (PIN) or other identification code associated with your
                    account.
                  </li>
                  <li>
                    Verify the uncertainty and authenticity of any notice of any
                    purported to be made by Gotok to your account.
                  </li>
                </ul>
                <p>
                  You are to take steps to secure your account details
                  adequately. We shall not be liable for failure to secure your
                  account details, resulting in unauthorised access to your
                  Account and/or results in the tampering of local or digital
                  currency kept in your Balances or any linked bank account(s).
                  You accept that you shall be held responsible for all
                  activities that occur through your account and accept all
                  risks thereof.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Restriction, Suspension, Termination</h2>
                <p>
                  Gotok reserves the right to restrict, suspend or terminate
                  your Gotok Account where:
                </p>
                <ul>
                  <li>
                    We reasonably suspect that your Gotok Account is the subject
                    of an operational or other error, in which case we suspend
                    access to your account until the error is rectified.
                  </li>
                  <li>
                    We reasonably suspect that your Gotok Account has been or is
                    being used with any unlawful, fraudulent or Prohibited
                    Activity, or breach of these Terms or our standard Terms of
                    Use;
                  </li>
                  <li>
                    We reasonably suspect that you or your Gotok Account is or
                    has been associated with or poses a high risk of money
                    laundering, financing of terrorism, fraud, or any other
                    financial crime.
                  </li>
                  <li>
                    We reasonably suspect you of taking any action that Gotok
                    considers to be a circumvention of Gotok’s controls,
                    including but not limited to opening multiple Gotok
                    Accounts.
                  </li>
                  <li>
                    We reasonably suspect your involvement in any attempt to
                    gain unauthorised access to any Gotok Account.
                  </li>
                  <li>
                    Your Gotok Account is or appears to be the subject of any
                    legal, regulatory or government process and/or we, in our
                    sole discretion, consider there to be a heightened risk of
                    legal or regulatory non-compliance.
                  </li>
                  <li>
                    We are compelled to do so by a prima facie valid subpoena,
                    court order, or other binding order of a government or
                    regulatory authority; or
                  </li>
                </ul>
                <p>
                  Gotok will make all reasonable efforts to notify you of any
                  decision to restrict, suspend or terminate your Gotok Account
                  unless we are prevented from doing so by any legal or
                  regulatory process or requirement or where doing so may
                  compromise Gotok’s security and/or risk management procedures.
                  You accept and agree that Gotok is under no obligation to
                  disclose to you the fact of or reason for any decision to
                  restrict, suspend or terminate your Gotok Account. Gotok shall
                  have no liability to you in connection with the restriction,
                  suspension or termination of your Gotok Account.
                </p>
                <p>You accept that, once your account is closed:</p>
                <ul>
                  <li>
                    We reserve the right to delete all your account information
                    on our server and the right to retain any information
                    required for legal and operational reasons
                  </li>
                  <li>It will not be accessible to you.</li>
                  <li>
                    We are under no obligation to notify you of or provide you
                    with any digital currency credited to your Account.
                  </li>
                </ul>
              </div>
              <div className="c-legal__text">
                <h2>Notice</h2>
                <p>
                  Gotok will provide any notices relating to your account or
                  your use of our service on the service or by mailing them to
                  the email address provided by you. You consent to receive all
                  notices electronically. ‍You have the hardware and software to
                  access, receive and save notices sent to you electronically.
                  All information provided to us is valid and up to date. Any
                  notification sent to the email address provided by you will be
                  deemed received once we send it.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Prohibited Activities</h2>
                <p>
                  You must not use your Gotok Account to undertake any of the
                  activities or categories of activity set out in this section
                  (each a “Prohibited Activity”):
                </p>
                <ul>
                  <li>
                    Violation of any laws, statutes, ordinances or regulations.
                    Undertaking, facilitating or supporting criminal activity of
                    any kind, including but not limited to money laundering,
                    terrorist financing, illegal gambling operations or
                    malicious hacking.
                  </li>
                  <li>
                    Abusive activity, including but not limited to imposing an
                    unreasonable or disproportionately large load on Gotok's
                    infrastructure or otherwise taking any action that may
                    negatively affect the performance of the Gotok Site or
                    Gotok’s reputation
                  </li>
                  <li>
                    Attempting to gain unauthorised access to the platform or
                    any Gotok Account. Transmitting or uploading any material to
                    the platform that contains viruses, Trojan horses, worms, or
                    any other harmful programmes.
                  </li>
                  <li>
                    Transferring your Gotok Account access or rights to your
                    Gotok Account to a third party, unless as required by law or
                    with Gotok's prior consent.
                  </li>
                  <li>
                    Fraudulent activity, including but not limited to taking any
                    actions that defraud Gotok or a Gotok customer, or the
                    provision of any false, inaccurate, or misleading
                    information to Gotok.
                  </li>
                  <li>
                    Transactions involving items that may help facilitate or
                    enable illegal activity; promote or facilitate hate,
                    violence or racial intolerance; are considered obscene, or
                    maybe stolen goods or the proceeds of crime.
                  </li>
                  <li>Intellectual property infringement.</li>
                  <li>
                    Sale or purchase of drug, narcotics or controlled substances
                    and paraphernalia, pseudo-Pharmaceuticals: Pharmaceuticals
                    and other products that make health claims that have not
                    been approved or verified by the applicable local and/or
                    national regulatory body.
                  </li>
                  <li>
                    Adult Content and Services: Pornography and other obscene
                    materials (including literature, imagery and other media);
                    sites offering any sexually related services such as
                    prostitution, escorts, pay-per-view, adult live chat
                    features.
                  </li>
                  <li>
                    Multi-level Marketing: Pyramid schemes, network marketing,
                    and referral marketing programs.
                  </li>
                  <li>
                    Unfair, predatory or deceptive practices: Investment
                    opportunities or other services that promise high rewards;
                    Sale or resale of a service without added benefit to the
                    buyer; resale of government offerings without authorisation
                    or added value; sites that we determine in our sole
                    discretion to be unfair, deceptive, or predatory towards
                    consumers.
                  </li>
                  <li>
                    High-risk businesses: any businesses that we believe poses
                    an elevated financial risk, legal liability, or violate card
                    network or bank policies.
                  </li>
                </ul>

                <p>
                  By opening a Gotok Account, you confirm that you will not use
                  your account to undertake any of the above-listed Prohibited
                  Activities or any similar or related activity.
                </p>
                <p>
                  Under no circumstances should you attempt to use your Gotok
                  account to store, send, request, or receive currency or
                  Digital Currencies in any form that Gotok does not support.
                  Gotok assumes no responsibility or liability in connection
                  with any attempt to use Gotok Services for a currency that
                  Gotok does not support.
                </p>
                <p>
                  The Service may contain cryptographic functionality where the
                  export of such could be restricted under applicable export
                  control laws. You shall not export or re-export the Service or
                  parts of it in violation of such laws or regulations.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Third-party Sites</h2>
                <p>
                  Our Service may contain links to other sites not operated by
                  us. If you click on a third-party link, it may direct you to
                  that third party's site. We strongly advise you to review the
                  Privacy Policy of every site you visit. We have no control
                  over and assume no responsibility for any third-party sites or
                  services' content, privacy policies, or practices. We do not
                  guarantee the identity of any user or third party.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Financial Advice</h2>
                <p>
                  No information provided by this service concerning your
                  account provided through any of our platforms shall constitute
                  financial, investment or other professional advice. You are
                  solely responsible for any decision to deposit, store, convert
                  and send digital currency. Such a decision should consider
                  your risk appetite and financial circumstances.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Taxes</h2>
                <p>
                  You are solely responsible for determining whether and to what
                  extent any taxes apply to any transactions you carry out
                  through your Gotok Account and for withholding, collecting,
                  reporting and remitting the correct tax amounts to the
                  appropriate tax authorities.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Disclaimers and Warranties</h2>
                <p>
                  Gotok expressly disclaims, and you waive, all warranties of
                  any kind, whether express or implied. We make no
                  representations about the accuracy of the historical digital
                  currency data available on the service. Without limiting the
                  generality of the preceding, your Gotok account and any
                  related products or services are offered without any warranty
                  as to merchantability or fitness for any particular purpose.
                  We will ensure that all requests are processed on time, but we
                  make no warranties regarding the length of time required to
                  complete processing which depends on factors outside our
                  control.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Limitation and Liability</h2>
                <p>
                  In no event shall Gotok, its operating entities or any other
                  affiliates (including their respective directors, members,
                  employees or agents) be liable to you for any direct,
                  indirect, special, consequential, exemplary or punitive
                  damages or any other damages of any kind, including but not
                  limited to loss of profit, loss of revenue, loss of business,
                  loss of opportunity, loss of data, whether in contract, tort
                  or otherwise, arising out of or in any way connected with your
                  use of, inability to use, or unavailability of your Gotok
                  Account, including without limitation any damages caused by or
                  resulting from any reliance upon any information received from
                  Gotok, or that result from mistakes, omissions, interruptions,
                  deletion of files or email, errors, defects, viruses, delays
                  in operation or transmission or any failure of performance,
                  whether or not resulting from a force majeure event,
                  communications failure, theft, destruction or unauthorised
                  access to Gotok's records, programmes or services.
                </p>
                <p>
                  Without limiting the generality of the above, in no event will
                  any liability of Gotok, its operating entities, or any other
                  affiliates (including their respective directors, members,
                  employees or agents) arise concerning your use of your Gotok
                  Account, exceed (in aggregate) the fees earned by Gotok in
                  connection with your use of your Gotok Account in the six
                  months immediately preceding the event giving rise to the
                  claim for liability. ‍The above limitations of liability shall
                  apply to the fullest extent permitted by law in the Federal
                  Republic of Nigeria.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Disputes Resolution</h2>
                <p>
                  You and we agree to notify the other party in writing of any
                  claim or dispute that arises in relation to the Gotok Site,
                  your Gotok Account or these Terms within 30 days of such claim
                  or dispute arising. You and we further agree to attempt
                  informal resolution of any Dispute before bringing a claim in
                  any court or other body.
                </p>
                <p>
                  This Agreement shall be governed by and construed per the laws
                  of the Federal Republic of Nigeria. Parties agree to submit
                  all disputes, claims or controversies (including
                  non-contractual Disputes, claims or controversies) arising out
                  of or in connection with these Terms, or the breach,
                  termination, enforcement or interpretation thereof (together,
                  Disputes), to the exclusive jurisdiction of the courts of
                  Nigeria.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>United States Legal Compliance</h2>
                <p>
                  You represent and warrant that (i) You are not located in a
                  country that is subject to the United States government
                  embargo, or that has been designated by the United States
                  government as a “terrorist supporting” country, and (ii) You
                  are not listed on any United States government list of
                  prohibited or restricted parties.
                </p>
              </div>

              <h4>Miscellaneous</h4>

              <div className="c-legal__text">
                <h2>Entire Agreement</h2>
                <p>
                  These Terms constitute the entire agreement and understanding
                  between you and Gotok concerning this subject matter and
                  supersede all prior discussions, agreements and understandings
                  of any kind between you and Gotok (including but not prior
                  versions of these Terms).
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Breach</h2>
                <p>
                  Should you suspect that your Account or any of your details
                  have been compromised or you become aware of any fraud or
                  attempted fraud or any other security incident affecting you
                  and/or Gotok. If that happens, you must notify immediately by
                  email at help@gotok.app and continue to provide information
                  throughout the Breach. You must take the required steps to
                  reduce and report any Breach. into account any any failure to
                  provide prompt notification of any Breach.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Account Inactivity</h2>
                <p>
                  If you have not accessed your account for a few years and we
                  cannot contact you but hold digital assets on your behalf, we
                  may be required to report and deliver such funds to the
                  relevant authorities as unclaimed money. We reserve the right
                  to deduct a dormancy fee or administrative charges incurred by
                  holding such unclaimed funds.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Agency</h2>
                <p>
                  Nothing in this Agreement shall be deemed or shall cause
                  either you or Gotok to be treated as the agent of each
                  other**.**
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Invalidity of Terms</h2>
                <p>
                  If any provision of these terms as may be amended periodically
                  is determined to be invalid or unenforceable, in part or whole
                  under any applicable law, this will not affect the validity of
                  any other provision as stated in these terms.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Transfer</h2>
                <p>
                  This agreement is personal to you, and you cannot transfer or
                  assign your rights, interests or obligations to anyone else.
                  We may transfer or assign our rights licenses, interests, and
                  obligations at any time, including as part of a merger,
                  acquisition, or other corporate restructuring, provided that
                  this transfer or assignment does not materially impact the
                  quality of our Services.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Force Majeure</h2>
                <p>
                  Gotok is not liable for a breach or non-performance of the
                  conditions of these terms to the extent that circumstances
                  beyond the control of Gotok cause the breach, its employees or
                  agents and for the period those circumstances circumstances
                  persist.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Security</h2>
                <p>
                  Gotok will not be held responsible for any claims over any
                  security.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Headings</h2>
                <p>
                  Clause headings in these terms are for convenience only and
                  shall not govern the meaning or interpretation of any
                  provision.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Translation Interpretation</h2>
                <p>
                  These Terms and Conditions may have been translated if We have
                  made them available to You on our Service. You agree that the
                  original English text shall prevail in the case of a dispute.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Contact Us</h2>
                <p>
                  If you have any questions about these Terms and Conditions,
                  You can contact us:
                </p>
                <ul>
                  <li>By email: help@gotok.app</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
    <Footer />
  </main>
);

export default Terms;
