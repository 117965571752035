import React from 'react';

function CoinSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="281"
      height="270"
      fill="none"
      className="c-referral__coinsvg"
    >
      <path
        fill="#2B11D9"
        d="m100.459 23.522-7.533 9.343c-1.632-.626-3.37-1.486-5.138-2.56l9.08-11.262c1.425 1.502 2.634 3.017 3.591 4.48Z"
      />
      <path
        fill="#2B25D9"
        d="m96.87 19.043-9.08 11.261a42.445 42.445 0 0 1-4.595-3.237l9.538-11.827a42.464 42.464 0 0 1 4.136 3.803Z"
      />
      <path
        fill="#2B00D9"
        d="m105.23 28.864-3.036 3.766c-.664.823-1.702 1.28-3.009 1.403l4.908-6.086 1.137.917Z"
      />
      <path
        fill="#2B07D9"
        d="m101.974 26.237 2.12 1.71-4.908 6.086c-1.706.16-3.87-.252-6.26-1.169l7.533-9.342a20.373 20.373 0 0 1 1.515 2.715Z"
      />
      <path
        fill="#8194D9"
        d="m83.002 9.445-7.533 9.342c-1.402-2.142-2.264-4.17-2.47-5.87l4.908-6.087 2.12 1.71c.936.202 1.934.506 2.975.905Z"
      />
      <path
        fill="#899ED9"
        d="m73.733 9.679 3.036-3.766 1.137.917L73 12.916c-.158-1.301.07-2.413.734-3.237Z"
      />
      <path
        fill="#3F50D9"
        d="M83.196 27.067a42.418 42.418 0 0 1-4.136-3.802l9.081-11.261a42.45 42.45 0 0 1 4.593 3.236l-9.538 11.827Z"
      />
      <path
        fill="#606ED9"
        d="m88.139 12.004-9.081 11.262c-1.425-1.501-2.634-3.017-3.591-4.48l7.534-9.341c1.631.625 3.369 1.485 5.138 2.56Z"
      />
      <path
        fill="url(#a)"
        d="M86.231 23.303c-7.86-6.337-12.096-14.122-9.462-17.389 2.634-3.266 11.14-.777 19 5.56 7.859 6.338 12.096 14.124 9.462 17.39-2.634 3.266-11.14.777-19-5.56Z"
      />
      <path
        fill="url(#b)"
        d="M86.816 22.578c-7.188-5.796-11.113-12.853-8.767-15.763s10.075-.57 17.262 5.226c7.188 5.796 11.113 12.853 8.767 15.762-2.346 2.91-10.074.57-17.262-5.225Z"
      />
      <path
        fill="url(#c)"
        d="M86.715 22.705c-6.946-5.6-10.8-12.342-8.61-15.058 2.19-2.716 9.595-.378 16.54 5.223 6.945 5.6 10.8 12.341 8.61 15.057-2.19 2.716-9.595.378-16.54-5.222Z"
      />
      <path
        fill="#A9A9FF"
        d="M76.77 5.913c1.571-1.95 5.234-1.848 9.603-.103l.4 1.035-.675.838 4.777 18.886a42.378 42.378 0 0 1-4.644-3.267c-7.86-6.337-12.096-14.123-9.462-17.39Z"
        style={{ mixBlendMode: 'soft-light' }}
      />
      <path
        fill="#2B11D9"
        d="m120.457 258.522-7.533 9.343c-1.632-.626-3.37-1.486-5.137-2.56l9.079-11.262c1.425 1.502 2.634 3.017 3.591 4.479Z"
      />
      <path
        fill="#2B25D9"
        d="m116.867 254.043-9.08 11.261a42.305 42.305 0 0 1-4.594-3.237l9.538-11.827a42.407 42.407 0 0 1 4.136 3.803Z"
      />
      <path
        fill="#2B00D9"
        d="m125.23 263.864-3.036 3.766c-.664.823-1.702 1.281-3.009 1.403l4.908-6.086 1.137.917Z"
      />
      <path
        fill="#2B07D9"
        d="m121.974 261.237 2.12 1.71-4.908 6.086c-1.706.16-3.87-.252-6.26-1.169l7.533-9.342a20.328 20.328 0 0 1 1.515 2.715Z"
      />
      <path
        fill="#8194D9"
        d="m103 244.445-7.533 9.342c-1.402-2.142-2.264-4.169-2.47-5.87l4.908-6.087 2.12 1.71c.936.202 1.934.506 2.975.905Z"
      />
      <path
        fill="#899ED9"
        d="m93.733 244.679 3.036-3.766 1.137.917L93 247.916c-.158-1.301.07-2.413.734-3.237Z"
      />
      <path
        fill="#3F50D9"
        d="M103.196 262.067a42.234 42.234 0 0 1-4.136-3.802l9.081-11.261a42.325 42.325 0 0 1 4.593 3.236l-9.538 11.827Z"
      />
      <path
        fill="#606ED9"
        d="m108.139 247.004-9.081 11.262c-1.425-1.501-2.634-3.017-3.591-4.479l7.534-9.342c1.631.625 3.369 1.485 5.138 2.559Z"
      />
      <path
        fill="url(#d)"
        d="M106.231 258.303c-7.86-6.337-12.096-14.122-9.462-17.389 2.634-3.266 11.14-.777 19 5.561 7.859 6.337 12.096 14.123 9.462 17.389-2.634 3.266-11.14.777-19-5.561Z"
      />
      <path
        fill="url(#e)"
        d="M106.816 257.578c-7.188-5.796-11.113-12.853-8.767-15.763 2.346-2.909 10.075-.569 17.262 5.226 7.188 5.796 11.113 12.853 8.767 15.763-2.346 2.909-10.074.569-17.262-5.226Z"
      />
      <path
        fill="url(#f)"
        d="M106.715 257.705c-6.946-5.6-10.8-12.342-8.61-15.058 2.189-2.716 9.595-.378 16.54 5.223 6.945 5.6 10.8 12.341 8.61 15.057-2.19 2.716-9.595.378-16.54-5.222Z"
      />
      <path
        fill="#A9A9FF"
        d="M96.77 240.913c1.571-1.949 5.234-1.848 9.603-.103l.4 1.035-.675.838 4.777 18.886a42.336 42.336 0 0 1-4.644-3.267c-7.86-6.337-12.096-14.123-9.462-17.389Z"
        style={{ mixBlendMode: 'soft-light' }}
      />
      <path
        fill="#2B11D9"
        d="m266.938 56.665-11.102 13.767c-2.405-.922-4.966-2.19-7.571-3.772l13.381-16.596c2.099 2.213 3.881 4.446 5.292 6.6Z"
      />
      <path
        fill="#2B25D9"
        d="M261.648 50.063 248.267 66.66a62.628 62.628 0 0 1-6.77-4.77l14.056-17.43a62.386 62.386 0 0 1 6.095 5.604Z"
      />
      <path
        fill="#2B00D9"
        d="m273.971 64.536-4.476 5.55c-.978 1.214-2.508 1.888-4.433 2.068l7.233-8.97 1.676 1.352Z"
      />
      <path
        fill="#2B07D9"
        d="m269.173 60.666 3.124 2.519-7.233 8.97c-2.513.234-5.703-.372-9.225-1.723l11.102-13.768c.899 1.374 1.648 2.717 2.232 4.002Z"
      />
      <path
        fill="#8194D9"
        d="m241.213 35.919-11.102 13.767c-2.066-3.156-3.335-6.144-3.638-8.65l7.232-8.97 3.124 2.52a30.16 30.16 0 0 1 4.384 1.333Z"
      />
      <path
        fill="#899ED9"
        d="m227.552 36.264 4.475-5.55 1.676 1.352-7.232 8.969c-.232-1.918.102-3.557 1.081-4.771Z"
      />
      <path
        fill="#3F50D9"
        d="M241.498 61.889a62.59 62.59 0 0 1-6.095-5.603l13.383-16.596a62.618 62.618 0 0 1 6.769 4.77l-14.057 17.429Z"
      />
      <path
        fill="#606ED9"
        d="m248.783 39.69-13.382 16.596c-2.099-2.211-3.881-4.445-5.292-6.6l11.102-13.767c2.405.921 4.966 2.189 7.572 3.771Z"
      />
      <path
        fill="url(#g)"
        d="M245.972 56.342c-11.582-9.34-17.826-20.813-13.944-25.626 3.881-4.814 16.417-1.145 28 8.194 11.582 9.34 17.826 20.813 13.944 25.627-3.881 4.813-16.417 1.144-28-8.195Z"
      />
      <path
        fill="url(#h)"
        d="M246.834 55.273c-10.593-8.542-16.377-18.941-12.92-23.229 3.457-4.288 14.847-.84 25.44 7.701 10.592 8.541 16.377 18.941 12.919 23.229-3.457 4.288-14.847.84-25.439-7.701Z"
      />
      <path
        fill="url(#i)"
        d="M246.685 55.46c-10.235-8.253-15.916-18.188-12.689-22.19 3.227-4.003 14.141-.557 24.376 7.696 10.235 8.253 15.916 18.188 12.689 22.19-3.228 4.003-14.141.557-24.376-7.696Z"
      />
      <path
        fill="#A9A9FF"
        d="M232.029 30.714c2.316-2.872 7.715-2.723 14.153-.152l.589 1.526-.995 1.235 7.04 27.832a62.467 62.467 0 0 1-6.843-4.814c-11.582-9.34-17.825-20.814-13.944-25.627Z"
        style={{ mixBlendMode: 'soft-light' }}
      />
      <path
        fill="url(#j)"
        d="m4.984 216 1.767 3.228 3.228 1.767-3.228 1.767-1.767 3.217-1.756-3.217L0 220.995l3.228-1.767L4.984 216Z"
      />
      <path
        fill="url(#k)"
        d="m126.98 143 2.465 4.515 4.515 2.465-4.515 2.465-2.465 4.515-2.465-4.515L120 149.98l4.515-2.465L126.98 143Z"
      />
      <path
        fill="url(#l)"
        d="m261.984 245 1.767 3.228 3.228 1.767-3.228 1.767-1.767 3.217-1.756-3.217-3.228-1.767 3.228-1.767 1.756-3.228Z"
      />
      <path
        fill="url(#m)"
        d="M149.798 16.753c-2.609.654-3.387 1.436-4.041 4.042a.264.264 0 0 1-.257.198.263.263 0 0 1-.256-.198c-.655-2.607-1.437-3.388-4.046-4.042a.263.263 0 0 1-.143-.418.268.268 0 0 1 .143-.095c2.609-.654 3.391-1.435 4.046-4.042A.262.262 0 0 1 145.5 12a.264.264 0 0 1 .257.198c.654 2.607 1.432 3.388 4.041 4.042a.268.268 0 0 1 .143.095.27.27 0 0 1 .055.162.27.27 0 0 1-.055.162.269.269 0 0 1-.143.094Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1="105.128"
          x2="78.065"
          y1="28.783"
          y2="6.962"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#868ED9" />
          <stop offset="1" stopColor="#8298D9" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="105.096"
          x2="82.579"
          y1="28.626"
          y2="10.47"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93A9D9" />
          <stop offset="1" stopColor="#2B14D9" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="103.039"
          x2="85.014"
          y1="27.755"
          y2="13.221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6165D9" />
          <stop offset=".54" stopColor="#8896D9" />
          <stop offset="1" stopColor="#8EB0D9" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="125.128"
          x2="98.065"
          y1="263.783"
          y2="241.962"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#868ED9" />
          <stop offset="1" stopColor="#8298D9" />
        </linearGradient>
        <linearGradient
          id="e"
          x1="125.096"
          x2="102.578"
          y1="263.626"
          y2="245.47"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93A9D9" />
          <stop offset="1" stopColor="#2B14D9" />
        </linearGradient>
        <linearGradient
          id="f"
          x1="123.039"
          x2="105.014"
          y1="262.755"
          y2="248.221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6165D9" />
          <stop offset=".54" stopColor="#8896D9" />
          <stop offset="1" stopColor="#8EB0D9" />
        </linearGradient>
        <linearGradient
          id="g"
          x1="273.821"
          x2="233.938"
          y1="64.418"
          y2="32.259"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#868ED9" />
          <stop offset="1" stopColor="#8298D9" />
        </linearGradient>
        <linearGradient
          id="h"
          x1="273.773"
          x2="240.589"
          y1="64.186"
          y2="37.429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93A9D9" />
          <stop offset="1" stopColor="#2B14D9" />
        </linearGradient>
        <linearGradient
          id="i"
          x1="270.742"
          x2="244.178"
          y1="62.902"
          y2="41.483"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6165D9" />
          <stop offset=".54" stopColor="#8896D9" />
          <stop offset="1" stopColor="#8EB0D9" />
        </linearGradient>
        <radialGradient
          id="j"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(73.067 -144.858 109.618) scale(10.5184 13.2999)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D82FF" />
          <stop offset=".295" stopColor="#C7C9FF" />
          <stop offset=".479" stopColor="#C7C9FF" />
          <stop offset=".638" stopColor="#C7C9FF" />
          <stop offset="1" stopColor="#7D82FF" />
        </radialGradient>
        <radialGradient
          id="k"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(73.067 -35.226 154.75) scale(14.7142 18.6053)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D82FF" />
          <stop offset=".295" stopColor="#C7C9FF" />
          <stop offset=".479" stopColor="#C7C9FF" />
          <stop offset=".638" stopColor="#C7C9FF" />
          <stop offset="1" stopColor="#7D82FF" />
        </radialGradient>
        <radialGradient
          id="l"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(73.067 -35.929 297.563) scale(10.5184 13.2999)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D82FF" />
          <stop offset=".295" stopColor="#C7C9FF" />
          <stop offset=".479" stopColor="#C7C9FF" />
          <stop offset=".638" stopColor="#C7C9FF" />
          <stop offset="1" stopColor="#7D82FF" />
        </radialGradient>
        <radialGradient
          id="m"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(73.062 63.227 102.626) scale(9.47918 11.989)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D82FF" />
          <stop offset=".295" stopColor="#C7C9FF" />
          <stop offset=".479" stopColor="#C7C9FF" />
          <stop offset=".638" stopColor="#C7C9FF" />
          <stop offset="1" stopColor="#7D82FF" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default CoinSVG;
