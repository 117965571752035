import Header, { LandingHeaderLinks } from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import '../legal.scss';

const AML_US = () => (
  <main className="l-wrapper-full">
    <Header>
      <LandingHeaderLinks />
    </Header>
    <section className="c-legal c-legal--privacy">
      <article className="c-legal__hero">
        <div className="l-wrapper">
          <div className="l-row">
            <div className="l-col-9 l-flex-column">
              <h5>Anti Money Laundering Policy</h5>
              <h3>
                The following document outlines Anti Money Laundering Policy
              </h3>
              <h6>Last updated: August 29, 2022</h6>
            </div>
          </div>
        </div>
      </article>

      <article className="c-legal__content">
        <div className="l-wrapper">
          <div className="l-row">
            <div className="l-col-7">
              <div
                className="c-legal__manifest"
                style={{ margin: '4.8rem 0 2.0rem 0', opacity: '.75' }}
              >
                <p>
                  The following are terms of accessing and/or using gotok’s
                  website and service (hereinafter “the Service”). By accepting
                  the terms below (hereinafter “the Agreement”) and/or using the
                  Service, you are stating that you agree to be bound by all
                  terms without modification, conditions or notices.
                </p>
                <p>
                  gotok was created by, and is a service of gotok (hereinafter
                  “gotok”)
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Overview</h2>
                <p>
                  We have no tolerance for money laundering, the financing of
                  terrorism or any other form of illicit activity, and are
                  committed to implementing appropriate policies, procedures and
                  controls to prevent those activities. Our policies are shaped
                  by industry best practices, a risk-based approach and the
                  effective anti-money laundering standards applied in The
                  United States of America, the European Union and worldwide.
                  These policies apply, without exception, to all employees of
                  Gotok Inc, its Board Members and Directors, as well as to its
                  subsidiaries.
                </p>
                <p>
                  The purpose of this is to provide Gotok Inc Clients,
                  Providers, Partners, Vendors, Contractors, Employees, Law
                  enforcement and other concerned stakeholders a high-level and
                  summarized overview of Gotok’s main AML/CTF policies and
                  procedures. This document and all underlying policies,
                  processes and procedures are prepared in line with provisions,
                  requirements and recommendations of:
                </p>
                <ul>
                  <li>
                    Money Laundering and Terrorist Financing Prevention Act, as
                    amended from time to time;
                  </li>
                  <li>
                    FATF Guidance for a Risk-Based Approach to Virtual Assets
                    and Virtual Assets Service Providers.
                  </li>
                </ul>
                <p>
                  Gotok operates from, and under the laws of The United States
                  of America.
                </p>
                <p>
                  As a registered business, Gotok is required to comply with the
                  Money Laundering and Terrorist Financing Prevention Act and
                  International Sanctions Act, which require Gotok to identify
                  and verify its clients’ identities appropriately, conduct
                  ongoing monitoring of their activity (including transaction
                  monitoring), maintain records of clients’ activity and related
                  documents for at least six years and report suspicious
                  transactions to authorities.
                </p>
                <p>Gotok understands Money Laundering as:</p>
                <ul>
                  <li>
                    The conversion or transfer of property, knowing that such
                    property is derived from criminal activity or from an act of
                    participation in such activity, for the purpose of
                    concealing or disguising the illicit origin of the property
                    or of assisting any person who is involved in the commission
                    of such an activity to evade the legal consequences of that
                    person’s action;
                  </li>
                  <li>
                    The concealment or disguise of the true nature, source,
                    location, disposition, movement, and rights with respect to,
                    or ownership of, property, knowing that such property is
                    derived from criminal activity or from an act of
                    participation in such activity;
                  </li>
                  <li>
                    The acquisition, possession or use of property, knowing, at
                    the time of receipt, that such property was derived from
                    criminal activity or from an act of participation in such
                    activity;
                  </li>
                  <li>
                    Participation in, association to commit, attempts to commit
                    and aiding, abetting, facilitating and counseling the
                    commission of any of the actions referred to in points
                    above.
                  </li>
                </ul>
                <p>Gotok understands Terrorist financing as:</p>
                <ul>
                  <li>
                    Provides funds for terrorist activity, meaning as the
                    provision or collection of funds, by any means, directly or
                    indirectly, with the intention that they are used or in the
                    knowledge that they are to be used, in full or in part, in
                    order to carry out any of the offences within the meaning of
                    the law. This activity is done by intentionally killing,
                    seriously harming or endangering a person, causing
                    substantial property damage that is likely to seriously harm
                    people or by seriously interfering with or disrupting
                    essential services, facilities or systems.
                  </li>
                </ul>
              </div>

              <div className="c-legal__text">
                <h2>Risk-Based Approach</h2>
                <p>
                  Gotok takes a risk-based approach (“RBA”) towards assessing
                  and containing the money laundering and terrorist financing
                  risks arising from any transactions it has with clients and
                  uses all available data when reviewing client activity.
                </p>
                <p>
                  Gotok performs risk-based due diligence and collects necessary
                  information such as (name, date of birth, address, and ID) and
                  documentation on each prospective client in order to assess
                  the risk profile. Before entering into a client relationship,
                  necessary checks are conducted in line with the RBA so as to
                  ensure that the identity of the clients does not match with an
                  entity with a known criminal background or with banned
                  entities, such as terrorist organizations.
                </p>
                <p>
                  Enhanced due diligence is required for clients who are deemed
                  to be of high risk, especially for transactions of higher
                  value and frequency, which can be determined by Gotok at its
                  sole and absolute discretion.
                </p>
                <p>
                  Gotok’s employees exercise care, due diligence and good
                  judgment in determining the overall profile and business
                  nature of its clients. Gotok conducts its business in
                  accordance with the highest ethical standards and may decide
                  not to enter into a client relationship that can adversely
                  affect Gotok’s reputation.
                </p>
                <p>
                  For the purpose of identification, assessment and analysis of
                  risks related to its activities, Gotok has established a risk
                  assessment, taking account of the following factors:
                </p>
                <ul>
                  <li>Client risk;</li>
                  <li>Geographical risk;</li>
                </ul>
                <p>
                  After the risk is assessed and attributed to a particular
                  client. Depending on the assigned degree of risk, it will be
                  revised periodically upon knowledge of the client and its
                  activities.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Client Due Diligence</h2>
                <p>
                  Gotok requires all individual clients to undergo proper due
                  diligence or Know Your Customer/Business (KYB/C) checks before
                  using our services. This includes, without limitation:
                </p>
                <ul>
                  <li>
                    A high-resolution, clearly readable, non-expired copy of the
                    individual’s government-issued ID or IDs (passport, national
                    identity card and/or a driver’s license);
                  </li>
                </ul>
                <p>
                  Additionally, for any clients deem to be of high risk, the
                  Identity Verification may include:
                </p>
                <ul>
                  <li>
                    A high-resolution, clearly readable, non-expired proof of
                    address document not older than 3 months old. The document
                    must carry the Client’s name and address (recent utility
                    bill or bank statement);
                  </li>
                </ul>
                <p>
                  Care must be taken that all documents provided are true copies
                  of the original. Providing false, forged, modified or
                  documents meant to deceive will be considered fraud and
                  treated as such. All assets derived from fraudulent
                  transactions and/or suspicious activity may be seized and
                  forfeited. Such activity may also be reported to the relevant
                  authorities. Gotok may use recognized and specialized
                  electronic providers for the technical acquisition of identity
                  data. Gotok may also decide to use the following
                  non-documentary methods of verifying identity:
                </p>
                <ul>
                  <li>
                    Independently verifying the Client’s identity through the
                    comparison of information provided by the Client with
                    information obtained from a consumer reporting agency,
                    public database or other sources;
                  </li>
                  <li>Checking references with other institutions;</li>
                  <li>
                    Analyzing whether there is logical consistency between the
                    identifying information provided, such as the Client’s name,
                    street address, postal code, and date of birth;
                  </li>
                  <li>
                    Utilizing complex device identification (such as “digital
                    fingerprints” or IP geolocation checks);
                  </li>
                </ul>
                <p>
                  terrorism financing activities, or where there shall be any
                  doubt about the adequacy or veracity of previously obtained
                  Clients’ identification data, further due diligence measures
                  shall be undertaken, including verifying the identity of the
                  Client again and obtaining information regarding the purpose
                  and intended nature of the relationship with Gotok.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Compliance Officer</h2>
                <p>
                  The management board of Gotok appointed a Compliance Officer,
                  who acts as a contact person of the FinCEN, and performs the
                  AML/CTF duties and obligations of Gotok. A Compliance Officer
                  reports directly to the management board and has the
                  competence, means and access to relevant information across
                  all the structural units of Gotok.
                </p>
                <p>
                  Only a person who has the education, professional suitability,
                  the abilities, personal qualities, experience and impeccable
                  reputation required for the performance of the duties listed
                  below may be appointed as a Compliance Officer.
                </p>
                <p>The duties of a Compliance Officer include, among others:</p>
                <ul>
                  <li>
                    The organisation of the collection and analysis of
                    information referring to unusual transactions or
                    transactions or circumstances suspected of money laundering
                    or terrorist financing, which have become evident in the
                    activities of Gotok;
                  </li>
                  <li>
                    Reporting in the event of suspicion of money laundering or
                    terrorist financing;
                  </li>
                  <li>
                    Periodic submission of written statements on compliance with
                    the requirements arising from the Act to the management
                    board of Gotok;
                  </li>
                  <li>
                    Performance of other duties and obligations related to
                    compliance with the requirements of Gotok;
                  </li>
                  <li>
                    Updating internal policy documents, and business and client
                    risk assessments regularly.
                  </li>
                </ul>
              </div>

              <div className="c-legal__text">
                <h2>Rules of Procedure & Internal Controls</h2>
                <p>
                  Gotok has developed and implemented rules of procedure that
                  allow for effective mitigation and management of risks
                  relating to money laundering and terrorist financing, which
                  are identified in the risk assessment performed in accordance
                  with Gotok’s risk-based approach. Each employee of Gotok must
                  strictly adhere to the rules of procedure set forth herein.
                </p>
                <p>The rules of procedure consist of the following:</p>
                <ul>
                  <li>
                    The organisation of the collection and analysis of
                    information referring to unusual transactions or
                    transactions or circumstances suspected of money laundering
                    or terrorist financing, which have become evident in the
                    activities of Gotok;
                  </li>
                  <li>
                    Reporting in the event of suspicion of money laundering or
                    terrorist financing;
                  </li>
                  <li>
                    Periodic submission of written statements on compliance with
                    the requirements arising from the Act to the management
                    board of Gotok;
                  </li>
                  <li>
                    Performance of other duties and obligations related to
                    compliance with the requirements of Gotok;
                  </li>
                  <li>
                    Updating internal policy documents, and business and client
                    risk assessments regularly.
                  </li>
                </ul>
              </div>

              <div className="c-legal__text">
                <h2>Rules of Procedure & Internal Controls</h2>
                <p>
                  Gotok has developed and implemented rules of procedure that
                  allow for effective mitigation and management of risks
                  relating to money laundering and terrorist financing, which
                  are identified in the risk assessment performed in accordance
                  with Gotok’s risk-based approach. Each employee of Gotok must
                  strictly adhere to the rules of procedure set forth herein.
                </p>
                <p>The rules of procedure consist of the following:</p>
                <ul>
                  <li>
                    a procedure for the application of due diligence measures
                    regarding a client, including a procedure for the
                    application of simplified and enhanced due diligence
                    measures;
                  </li>
                  <li>
                    a model for identification and management of risks relating
                    to a client and its activities and the determination of the
                    client’s risk profile;
                  </li>
                  <li>
                    the methodology and instructions where Gotok has a suspicion
                    of money laundering and terrorist financing or an unusual
                    transaction or circumstance is involved as well as for
                    instructions for performing the reporting obligation; the
                    procedure for data retention and making data available;
                  </li>
                  <li>
                    instructions for effectively identifying whether a person is
                    a politically exposed person or a local politically exposed
                    person subject to international sanctions.
                  </li>
                </ul>
                <p>
                  Gotok applies at least the following due diligence measures:
                </p>
                <ul>
                  <li>
                    Requests identification of the Individual based on
                    documentation submitted by the Client;
                  </li>
                  <li>
                    Performing additional due diligence for the Client and its
                    transactions, as necessary per established risk assessment
                    policies and procedures;
                  </li>
                  <li>
                    Maintains ongoing monitoring of the business relationship
                    and transactions.
                  </li>
                </ul>
              </div>

              <div className="c-legal__text">
                <h2>Simplified Due Diligence</h2>
                <p>
                  Gotok may apply simplified due diligence (“SDD“) measures
                  where a risk assessment prepared on the basis of these rules
                  of procedure identifies that, in the case of the jurisdiction,
                  economic sector of activity or amounts transacted the risk of
                  money laundering or terrorist financing is lower than usual.
                </p>
                <p>
                  Before the application of SDD measures to a client, an
                  employee of Gotok establishes that the business relationship,
                  transaction or act is of a lower risk and Gotok attributes to
                  the transaction, act or client a lower degree of risk.
                </p>
                <p>
                  The application of SDD measures is permitted to the extent
                  that Gotok ensures sufficient monitoring of transactions, acts
                  and business relationships so that it would be possible to
                  identify unusual transactions and allow for notifying of
                  suspicious transactions in accordance with these rules of
                  procedure.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Enhanced Due Diligence</h2>
                <p>
                  Gotok applies enhanced due diligence (“EDD“) measures in order
                  to adequately manage and mitigate a higher-than-usual risk of
                  money laundering and terrorist financing. EDD measures are
                  applied always when:
                </p>
                <p>Prior to client onboarding:</p>
                <ul>
                  <li>
                    Upon analysis of submitted client information and documents,
                    there are reasonable doubts as to the truthfulness of the
                    submitted data, authenticity of the documents;
                  </li>
                  <li>
                    The client is located in a jurisdiction classified as high
                    risk (eg: in jurisdictions that have not established
                    effective AML/CTF systems that are in accordance with the
                    recommendations of the Financial Action Task Force).
                  </li>
                </ul>
                <p>After client onboarding:</p>
                <ul>
                  <li>
                    When the client processed transactional volume exceeds the
                    assigned risk threshold for the client;
                  </li>
                  <li>
                    If unusual or suspicious patterns of activity are detected;
                  </li>
                </ul>
                <p>
                  Gotok also applies EDD measures whereas the assessment of risk
                  is assessed as higher, in accordance with its internal
                  policies and procedures.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Sector and Jurisdiction Restrictions</h2>
                <p>
                  We do not serve Clients from certain jurisdictions that are
                  deemed too high-risk and/or unwelcoming from a legal or
                  regulatory perspective.
                </p>
                <p>
                  We only service clients that are based in Nigeria where we
                  operate. Clients based in non-serviced jurisdictions cannot
                  access or be onboarded to use our services. Attempts to
                  circumvent this policy, by providing false, forged or modified
                  documents meant to deceive or mislead will be considered fraud
                  and treated as such by law enforcement.
                </p>
              </div>
              <div className="c-legal__text">
                <h2>Politically Exposed Persons</h2>
                <p>
                  Politically Exposed Persons (“PEP“) (as well as their families
                  and persons known to be close associates, as described below)
                  are required to be subject to enhanced scrutiny by reporting
                  entities. This is because international standards issued by
                  the Financial Action Task Force recognize that a PEP may be in
                  a position to abuse their public office for private gain and a
                  PEP may use the financial system to launder the proceeds of
                  this abuse of office.
                </p>
                <p>
                  PEP means a natural person who is or who has been entrusted
                  with prominent public functions including:
                </p>
                <ul>
                  <li>head of State;</li>
                  <li>head of government</li>
                  <li>minister and deputy or assistant minister</li>
                  <li>
                    a member of parliament or of a similar legislative body
                  </li>
                  <li>a member of a governing body of a political party;</li>
                  <li>a member of a supreme court;</li>
                  <li>
                    a member of a court of auditors or of the board of a central
                    bank;
                  </li>
                  <li>
                    an ambassador, a chargé d’affaires and a high-ranking
                    officer in the armed forces;
                  </li>
                  <li>
                    a member of an administrative, management or supervisory
                    body of a State-owned enterprise;
                  </li>
                  <li>
                    a director, deputy director and member of the board or
                    equivalent function of an international organization.
                  </li>
                </ul>
                <p>
                  PEPs do not include middle-ranking or more junior officials. A
                  family member of a PEP means the spouse, or a person
                  considered to be equivalent to a spouse, of a PEP or local
                  PEP; a child and their spouse, or a person considered to be
                  equivalent to a spouse, of a PEP or local PEP; a parent of a
                  PEP or local PEP.
                </p>
                <p>
                  A person known to be a close associate of a PEP means a
                  natural person who is known to be the beneficial owner or to
                  have joint beneficial ownership of a legal person or a legal
                  arrangement, or any other close business relations, with a PEP
                  or a local PEP; and a natural person who has sole beneficial
                  ownership of a legal entity or legal arrangement which is
                  known to have been set up for the de facto benefit of a PEP or
                  local PEP.
                </p>
              </div>
              <div className="c-legal__text">
                <h2>Sanctions</h2>
                <p>
                  Dealing with persons against which imposed international
                  sanctions poses a great risk to Gotok, its directors, officers
                  and owners. Gotok may employ automated screening software to
                  identify and block known virtual asset addresses associated
                  with sanctions and numerous illegal and high-risk activities.
                  Per its established policy, Gotok does not do business with
                  individuals under sanctions. Sanction lists considered
                  include, among others:
                </p>
                <ul>
                  <li>EU Sanctions;</li>
                  <li>UN Sanctions;</li>
                  <li>
                    Sanctions are administered by the Office of Foreign Assets
                    Control (“OFAC-US”).
                  </li>
                  <li>
                    All verified matches are automatically blocked and the
                    matter escalated to a Compliance Officer for further
                    analysis and appropriate actions.
                  </li>
                </ul>
              </div>
              <div className="c-legal__text">
                <h2>Suspicious Activity Monitoring and Reporting</h2>
                <p>
                  An investigation into suspicious activity will try to
                  establish the true motivation behind the activity in question.
                  This may result in confirmation of the suspicious activity or
                  removal of reasonable doubt. If suspicious activity is
                  confirmed, the issue will be escalated accordingly both
                  internally and externally. When such suspicious activity is
                  detected, the Compliance Officer will determine whether a
                  filing with any law enforcement authority is necessary.
                </p>
                <p>
                  Where Gotok identifies an activity or facts whose
                  characteristics refer to the use of criminal proceeds or
                  terrorist financing or other criminal offences or an attempt
                  thereof or with regard to which Gotok suspects or knows that
                  it constitutes money laundering or terrorist financing or the
                  commission of another criminal offence, a Compliance Officer
                  of Gotok must report it to the FinCEN diligently.
                </p>
                <p>
                  When such suspicious activity is detected, the Compliance
                  Officer will determine whether a filing with any law
                  enforcement authority is necessary. Gotok and all its
                  employees, officers and directors are prohibited to inform a
                  person, its beneficial owner, representative or third party
                  about a report submitted on them to the FinCEN, an intention
                  to submit such a report as well as about the commencement of
                  criminal proceedings
                </p>
              </div>
              <div className="c-legal__text">
                <h2>Termination of Services</h2>
                <p>
                  Gotok reserves the right to deny or terminate servicing a
                  client or account at any time in line with the terms
                  stipulated in the User Agreement if suspicion arises that a
                  Client is involved with or connected with money laundering,
                  criminal activity, terrorist financing or any other predicate
                  offence to money laundering or terrorist financing.
                </p>
              </div>
              <div className="c-legal__text">
                <h2>Data Retention</h2>
                <p>
                  Gotok is obligated to retain all documents and information
                  which serve for identification and verification of the client,
                  for a period of no less than 8 (eight) years after termination
                  of service. Gotok implements necessary rules for the
                  protection of personal data upon application of the
                  requirements arising from its obligations hereunder.
                </p>
                <p>
                  Gotok is allowed to process personal data gathered upon
                  implementation of these rules only for the purpose of
                  preventing money laundering and terrorist financing and the
                  data must not be additionally processed in a manner that does
                  not meet the purpose, for instance, for marketing purposes.
                </p>
              </div>
              <div className="c-legal__text">
                <h2>Training</h2>
                <p>
                  The Compliance Officer shall ensure that Company’s employees
                  are fully aware of their legal obligations under the AML/CTF
                  regime, by introducing a complete employees’ education and
                  training program.
                </p>
                <p>
                  The timing and content of the training provided are determined
                  according to the needs of Gotok. The frequency of the training
                  can vary depending on the amendments of legal and/or
                  regulatory requirements, employees’ duties as well as any
                  other changes in the business model. The training program aims
                  at educating Gotok’s employees on the latest developments in
                  the prevention of money laundering and terrorist financing,
                  including the practical methods and trends used for this
                  purpose.
                </p>
              </div>
              <div className="c-legal__text">
                <h2>Cooperation and Information Requests</h2>
                <p>
                  Gotok is required to cooperate with supervisory and law
                  enforcement authorities in preventing money laundering and
                  terrorist financing, thereby communicating information
                  available to Gotok and replying to queries within a reasonable
                  time, following the duties, obligations and restrictions
                  arising from legislation. As part of its duties, as per
                  applicable law and company policy, we are required to assist
                  enforcement agencies’ requests. We comply with Law Enforcement
                  requests for information where it pertains to specific
                  preservation orders and fund freezing.
                </p>
                <p>
                  We will not and do not voluntarily disclose non-public
                  information to a requesting party. In accordance with privacy
                  laws, Gotok will only disclose non-public user information if
                  it has received the consent of the user and in response to a
                  legitimate and an enforceable subpoena, court order or search
                  warrant from a body that has jurisdiction to compel Gotok to
                  disclose that information. Please note that in case you
                  represent a law enforcement agency outside of the European
                  Union, the procedure under the Mutual Legal Assistance Treaty
                  (“MLAT”) may apply. We take data protection and security
                  seriously and encourage you to consult our Terms of Service
                  for more information.
                </p>
                <p>General Guidelines for Requests:</p>
                <ul>
                  <li>
                    When law enforcement agencies request non-public information
                    (such as a client's personal or financial information), we
                    will not share this information unless enforceable court
                    order, subpoena or search warrant has been issued, received
                    and validated as legitimate;
                  </li>
                  <li>
                    We will notify affected clients if we believe we are legally
                    required to provide their personal or financial information
                    to a law enforcement agency unless we are prohibited by law
                    from doing so;
                  </li>
                  <li>
                    When law enforcement agencies request information about a
                    client, we cannot and will not provide information about
                    such client’s clients who are not our clients or platform
                    users. We consider this information to be in the possession,
                    control and custody of the client, who is the controller and
                    processor of such information. If law enforcement agencies
                    request this information, such requests for information
                    should be directed to the relevant client and not us;
                  </li>
                  <li>
                    Only information specifically requested and clearly outlined
                    in an enforceable court order, subpoena or search warrant
                    will be disclosed.
                  </li>
                </ul>
                <p>
                  This policy does not constitute legal advice or a promise or
                  guarantee that we will respond to any requests for information
                  in a specific way, timeframe or at all. All legal requests for
                  information are evaluated on a case-by-case basis. We reserve
                  the right to change this policy or these guidelines at our
                  sole discretion at any time.
                </p>
                <p>
                  When requesting the confirmation of the existence of data on
                  our platform the law enforcement agency must be very specific
                  about what information it is looking to obtain as we may not
                  be able to respond to vague, ambiguous or blanket requests.
                  Certain identifiers may be helpful in determining whether we
                  currently retain the requested information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
    <Footer />
  </main>
);

export default AML_US;
