import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
// import { TwitterTweetEmbed } from 'react-twitter-embed';
// import { IntercomProvider, useIntercom } from 'react-use-intercom';
// import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import Header, { LandingHeaderLinks } from '../../components/header/header';
import Footer from '../../components/footer/footer';
import 'react-toastify/dist/ReactToastify.css';
import './landing.scss';

import PhoneImage from './images/phone2.webp';
import TopImage from './images/top.webp';
import LeftImage from './images/bottom.webp';
import BottomImage from './images/left.webp';
import GooglePlay from './images/Google-play.svg';
import AppStore from './images/Appstore.svg';
import PSNCard from './images/psn-card.svg';
import AmazonCard from './images/amazon-card.svg';
import AppleCard from './images/apple-card.svg';
// import BinanceLogo from './images/binance_pay.svg';
// import SolanaPay from './images/solana_pay.svg';
import CardFunding from './images/card-funding.svg';
import CardVariant from './images/cards-variant.svg';
import CardMultiple from './images/card-multiple.svg';
import CardLimit from './images/limit.svg';
import FeaturesBackgroundLogo from './images/logo-bg.svg';
import FeaturesBackgroundLCard from './images/card-bg.svg';
import FeaturesImageOne from './images/feat-pattern-one.svg';
import VerifiedCheck from './images/check-verified.svg';
import CoinFeatures from './images/coin-features.svg';
import CoinStar from './images/coin-star.svg';
import PaperPlanes from './images/paper-planes.svg';
import LighteningIllustration from './images/lightening.svg';
import HowCardSVG from './images/how-card.svg';
import HowAccountSVG from './images/how-face.svg';
import HowBuySVG from './images/how-buy.svg';
import HowBuyPhone from './images/howtophone.png';
import HowCardStack from './images/cards-stack.svg';
import HowCardStackGlow from './images/cards-stack-glow.svg';
import HowPatternTwo from './images/howto-pattern-two.svg';
import HowPatternOne from './images/howto-pattern-one.svg';
import HowPatternThree from './images/howto-pattern-three.svg';
import CtaCoinSVG from './images/cta-coin.svg';
import CtaCardsPNG from './images/cta-cards.png';
import CtaGlitzSVG from './images/cta-glitz.svg';
// import CtaCardsSVG from './images/cta-cards.svg';
// import HowCoinSVG from './images/hand-coin.svg';
// import ManifestCurrencySVG from './images/currency.svg';

const { REACT_APP_TITLE, REACT_APP_SUBTITLE } = process.env;

/* Framer Motion Variant Options */
// header animation
const headerMediaAnimation = {
  container: {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.28,
        delay: 0.1,
        delayChildren: 0.2,
        duration: 0.3,
      },
    },
  },

  firstImage: {
    hidden: { opacity: 0, scale: 0.5, y: 350 },
    show: { opacity: 1, scale: 1, y: 0 },
  },

  secondImage: {
    hidden: {
      opacity: 0,
      // x: -100,
      clipPath: 'inset(10% 50% 90% 50% round 10px)',
    },
    show: {
      opacity: 1,
      // x: 0,
      clipPath: 'inset(0% 0% 0% 0% round 10px)',
    },
  },

  thirdImage: {
    hidden: {
      opacity: 0,
      // x: 100,
      clipPath: 'inset(10% 50% 90% 50% round 10px)',
    },
    show: {
      opacity: 1,
      // x: 0,
      clipPath: 'inset(0% 0% 0% 0% round 10px)',
    },
  },

  fourthImage: {
    hidden: {
      opacity: 0,
      // x: -100,
      clipPath: 'inset(10% 50% 90% 50% round 10px)',
    },
    show: {
      opacity: 1,
      // x: 0,
      clipPath: 'inset(0% 0% 0% 0% round 10px)',
    },
  },
};
// end header animation

// features animation
const featuresAnimation = {
  container: {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.28,
        delay: 0.1,
        delayChildren: 0.2,
        duration: 0.3,
      },
    },
  },
  intro: {
    start: {
      opacity: 0,
      y: 200,
    },
    end: {
      opacity: 1,
      y: 0,
    },
  },
  card: {
    one: {
      start: {
        opacity: 0,
        y: 350,
      },
      end: {
        opacity: 1,
        y: 0,
      },
    },
  },
};
// end features animation
/* end Framer Motion Options */

function Landing() {
  // const ref = useRef(null);
  // const isInView = useInView(ref, { once: true });

  // const options = {
  //   smooth: true,
  // };

  const el = useRef();
  const q = gsap.utils.selector(el);
  const tl = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap
        .timeline()
        .from(q('.hidetextref'), {
          duration: 1.5,
          y: '150%',
          stagger: 0.6,
          ease: 'power4.out',
          delay: 0.6,
        })
        .from(q('.hidepara'), {
          duration: 0.8,
          y: '150%',
          stagger: 0.6,
          delay: 1.3,
        });
    }, el);

    return () => ctx.revert();
  }, [q]);

  return (
    // <LocomotiveScrollProvider options={options} containerRef={ref}>
    <main>
      <div className="c-home">
        <section className="c-manifest">
          <div className="c-grid" />
          <div className="c-overlay" />
          <Header>
            <LandingHeaderLinks />
          </Header>
          <div className="l-wrapper">
            <div className="l-row">
              <div className="l-col-12">
                <motion.div
                  // initial={{ y: 400 }}
                  // animate={{ y: 0 }}
                  // transition={{ duration: 0.45 }}
                  className="c-manifest__text"
                  ref={el}
                >
                  <Link to="/referral" className="referral">
                    Refer a friend & Get 25%<span>→</span>
                  </Link>
                  <h2>
                    <span className="hidetextref">{REACT_APP_TITLE}</span>

                    {/* <span>
                      cash on
                      <i className="word-wrap">
                        <span className="animatedword animated-in">
                          {' '}
                          <img src={amazonImage} alt="amazon" /> amazon
                        </span>
                        <span
                          className="animatedword"
                          style={{ color: '#04c167' }}
                        >
                          {' '}
                          <img src={uberImage} alt="uber" /> uberEats
                        </span>
                        <span
                          className="animatedword"
                          style={{ color: '#ff615d' }}
                        >
                          {' '}
                          <img src={tinderImage} alt="tinder" /> tinder
                        </span>
                        <span
                          className="animatedword"
                          style={{ color: '#007ec9' }}
                        >
                          <img src={psnImage} alt="psn network" /> Playstation
                        </span>
                      </i>
                    </span> */}
                  </h2>
                  <p>
                    <span className="hidepara">{REACT_APP_SUBTITLE}</span>
                  </p>
                  {/* <Email /> */}
                  {/* <img
                    src={ManifestCurrencySVG}
                    alt="currency"
                    className="c-manifest__illustration c-manifest__illustration--currency"
                  /> */}
                  <div className="c-manifest__buttons">
                    <a
                      className=""
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=app.gotok"
                      rel="noreferrer"
                    >
                      {' '}
                      <img
                        src={GooglePlay}
                        alt="Playstore download"
                        className=""
                      />{' '}
                    </a>
                    <a
                      className=""
                      target="_blank"
                      href="https://apps.apple.com/ng/app/gotok/id6444884980"
                      rel="noreferrer"
                    >
                      {' '}
                      <img
                        src={AppStore}
                        alt="Appstore download"
                        className=""
                      />{' '}
                    </a>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
          <section className="c-media">
            <div className="l-wrapper">
              <div className="l-row">
                <motion.div
                  variants={headerMediaAnimation.container}
                  initial="hidden"
                  animate="show"
                  className="c-media__images-wrap"
                >
                  <motion.img
                    variants={headerMediaAnimation.firstImage}
                    src={PhoneImage}
                    alt=""
                    className="c-media__image c-media__image--1"
                  />
                  <motion.img
                    variants={headerMediaAnimation.secondImage}
                    src={TopImage}
                    alt=""
                    className="c-media__image c-media__image--2"
                  />
                  <motion.img
                    variants={headerMediaAnimation.thirdImage}
                    src={LeftImage}
                    alt=""
                    className="c-media__image c-media__image--3"
                  />
                  <motion.img
                    variants={headerMediaAnimation.fourthImage}
                    src={BottomImage}
                    alt=""
                    className="c-media__image c-media__image--4"
                  />
                </motion.div>
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    delay: 0.13,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  //   delay: 0.1, //   ease: [0, 0.71, 0.2, 1.01],
                  src={AmazonCard}
                  alt=""
                  className="c-media__image c-media__image--5"
                />
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    delay: 0.13,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  src={PSNCard}
                  alt=""
                  className="c-media__image c-media__image--6"
                />
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    delay: 0.13,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  src={AppleCard}
                  alt=""
                  className="c-media__image c-media__image--7"
                />
              </div>
            </div>
          </section>
        </section>

        <section className="c-wordmark">
          <div className="l-wrapper-full">
            <div className="l-row">
              <div className="l-col-12">
                <div className="c__marquee c__marquee_hoverpause">
                  <div className="c__marquee__group">
                    <p>
                      <img src={VerifiedCheck} alt="" /> Pay bills with Gotok ✨
                    </p>
                    <p aria-hidden="true">
                      <img src={VerifiedCheck} alt="" /> Easiest way to spend
                      online ✨
                    </p>
                    <p aria-hidden="true">
                      <img src={VerifiedCheck} alt="" /> Refer a friend and get
                      earn 25% ✨
                    </p>
                    <p aria-hidden="true">
                      <img src={VerifiedCheck} alt="" /> Spend internationally
                      with USD card ✨
                    </p>
                  </div>
                  <div aria-hidden="true" className="c__marquee__group">
                    <p>
                      <img src={VerifiedCheck} alt="" /> Pay bills with Gotok ✨
                    </p>
                    <p aria-hidden="true">
                      <img src={VerifiedCheck} alt="" /> Easiest way to spend
                      online ✨
                    </p>
                    <p aria-hidden="true">
                      <img src={VerifiedCheck} alt="" /> Refer a friend and get
                      earn 25% ✨
                    </p>
                    <p aria-hidden="true">
                      <img src={VerifiedCheck} alt="" /> Spend internationally
                      with USD card ✨
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="c-features">
          <img
            src={FeaturesBackgroundLogo}
            alt=""
            className="c-features__background c-features__background--top"
            role="presentation"
          />
          <img
            src={FeaturesBackgroundLCard}
            alt=""
            className="c-features__background c-features__background--bottom"
            role="presentation"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="http://svgjs.dev/svgjs"
            height="100%"
            width="100%"
            viewBox="0 0 2000 2000"
            preserveAspectRatio="none"
            opacity="1"
            style={{ position: 'absolute' }}
            className="c-referral__noise"
          >
            <defs>
              <filter
                id="nnnoise-filter"
                x="-20%"
                y="-20%"
                width="140%"
                height="140%"
                filterUnits="objectBoundingBox"
                primitiveUnits="userSpaceOnUse"
                colorInterpolationFilters="linearRGB"
              >
                <feTurbulence
                  type="fractalNoise"
                  // baseFrequency="0.101"
                  baseFrequency="0.13"
                  numOctaves="4"
                  seed="15"
                  stitchTiles="stitch"
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                  result="turbulence"
                />
                <feSpecularLighting
                  surfaceScale="14"
                  specularConstant="1"
                  specularExponent="20"
                  lightingColor="#fdd78b"
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                  in="turbulence"
                  result="specularLighting"
                >
                  <feDistantLight azimuth="3" elevation="101" />
                </feSpecularLighting>
              </filter>
            </defs>
            <rect width="100%" height="100%" fill="transparent" />
            <rect
              width="100%"
              height="100%"
              fill="#fdd78b"
              filter="url(#nnnoise-filter)"
            />
          </svg>

          <div className="l-wrapper">
            <div className="l-row">
              <div className="l-col-12">
                <motion.div
                  className="c-features__intro"
                  transition={{
                    delay: 0.2,
                    type: 'Tween',
                    bounce: 0.4,
                    duration: 1,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  initial={featuresAnimation.intro.start}
                  whileInView={featuresAnimation.intro.end}
                  viewport={{ once: true, margin: '-400px 0px 0px 100px' }}
                >
                  <h4>
                    Create a{' '}
                    <span className="c-features__virtual">
                      dollar virtual card{' '}
                      <span className="no-gradient">💳</span>{' '}
                    </span>
                    fund it from your <span className="no-gradient">💼</span>{' '}
                    bank, binance or solana wallet
                  </h4>
                  <h4>
                    <span className="">
                      {' '}
                      <span>make payments</span> on Amazon, PayPal, Netflix and
                      anywhere online <span className="no-gradient">🤯</span>{' '}
                      <span className="no-gradient">🤩</span>{' '}
                    </span>
                  </h4>
                </motion.div>
              </div>
            </div>
            <motion.div
              className="l-row"
              variants={featuresAnimation.container}
              initial="hidden"
              animate="show"
            >
              <div className="l-col l-col-7">
                <motion.div
                  className="c-features__card c-features__card--left"
                  transition={{
                    delay: 0.2,
                    type: 'Tween',
                    bounce: 0.4,
                    duration: 1,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  initial={featuresAnimation.intro.start}
                  whileInView={featuresAnimation.intro.end}
                  viewport={{ once: true, margin: '-400px 0px 0px 100px' }}
                >
                  <div className="c-features__grid" />
                  <div className="c-features__overlay c-features__overlay--first" />
                  <img
                    src={FeaturesImageOne}
                    alt=""
                    className="c-features__image c-features__image--one"
                  />

                  <div className="c-features__heading">
                    <h4>
                      {' '}
                      <span> Flexible </span> funding options
                    </h4>
                    <p>
                      Funding ease at its best with gotok with multiple options
                      allowing users flexibility when it comes to financing
                      their purchases.
                    </p>
                    <a
                      href="https://web.gotok.app/"
                      className="c-features__link"
                      alt="link to download gotok"
                    >
                      Download Gotok
                    </a>
                    <img
                      src={CoinFeatures}
                      alt="card funding"
                      className="coin-img"
                    />
                    <img
                      src={CoinStar}
                      alt="card funding"
                      className="coin-star"
                    />
                    <img
                      src={CardFunding}
                      alt="card funding"
                      className="funding-img"
                    />
                  </div>
                </motion.div>
              </div>
              <div className="l-col l-col-5">
                <motion.div
                  className="c-features__card"
                  transition={{
                    type: 'Tween',
                    bounce: 0.4,
                    duration: 0.6,
                    delay: 0.1,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  initial={featuresAnimation.card.one.start}
                  whileInView={featuresAnimation.card.one.end}
                  viewport={{ once: true }}
                >
                  <div className="c-features__grid" />
                  <div className="c-features__overlay" />
                  <div className="c-features__heading">
                    <h4>
                      Over <span style={{ fontFamily: 'Rotunda' }}>$4000 </span>{' '}
                      daily limit
                    </h4>
                    <p>
                      The daily limit of over $4000 for transactions provides
                      users with a high level of flexibility when it comes to
                      making purchases
                    </p>
                    <img
                      src={PaperPlanes}
                      alt="card funding"
                      className="coin-img"
                    />
                    <img src={CardLimit} alt="" className="limit-img" />
                  </div>
                </motion.div>
              </div>
              <div className="l-col l-col-5">
                <div className="c-features__card c-features__card--left">
                  <div className="c-features__grid" />
                  <div className="c-features__overlay c-features__overlay--multiple" />
                  <div className="c-features__heading">
                    <h4>
                      Create <span>multiple cards</span>
                    </h4>
                    <p>
                      Say goodbye to the hassle of constantly canceling and
                      reissuing cards by creating virtual ones for specific
                      purposes.
                    </p>
                    <a
                      href="https://web.gotok.app/"
                      className="c-features__link c-features__link--alt"
                      alt="link to download gotok"
                    >
                      Create an account
                    </a>
                    <img src={CardMultiple} alt="" className="multiple-img" />
                  </div>
                </div>
              </div>
              <div className="l-col l-col-7">
                <div className="c-features__card">
                  <div className="c-features__grid" />
                  <div className="c-features__overlay c-features__overlay--multiple" />
                  <div className="c-features__heading">
                    <h4>
                      Different <span>card variants</span>
                    </h4>
                    <p style={{ fontSize: '1.78rem' }}>
                      Master Card or Visa Choose from a variety of card options
                      that fit your unique style and needs
                    </p>
                    <img
                      src={LighteningIllustration}
                      alt="card funding"
                      className="strike"
                    />
                    <img src={CardVariant} alt="" className="variant-img" />
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="c-howto">
          <div className="l-wrapper">
            <div className="l-row">
              <div className="l-col-12">
                <div className="c-howto__intro">
                  <h3>Spend 💸</h3>
                  <h3>
                    {' '}
                    <span>manage and pay</span> 🍿
                  </h3>
                  <h3> 🛩️ bills with Gotok</h3>
                </div>
              </div>
            </div>

            <div className="l-row">
              <div className="l-col-12">
                <div className="c-howto__appshot">
                  <p>
                    Getting started on Gotok just takes just few steps. No
                    hassles
                  </p>
                  <div className="l-row">
                    <div
                      className="l-col l-col-6"
                      style={{ position: 'relative' }}
                    >
                      <article className="c-howto__steps c-howto__steps--one">
                        <img
                          src={HowPatternOne}
                          alt="steps to download"
                          className="c-howto__image c-howto__image--patternone"
                        />
                        <img
                          src={HowPatternOne}
                          alt="steps to download"
                          className="c-howto__image c-howto__image--patternonealt"
                        />
                        <div className="c-howto__step">
                          {/* <span>1</span> */}
                          <div>
                            <h4>Get started</h4>
                            <p>
                              To create an account download the gotok app on
                              mobile or visit gotok.app on the web{' '}
                            </p>
                            <div className="c-howto__buttons">
                              <a
                                className=""
                                target="_blank"
                                href="https://play.google.com/store/apps/details?id=app.gotok"
                                rel="noreferrer"
                              >
                                {' '}
                                <img
                                  src={GooglePlay}
                                  alt="Playstore download"
                                  className=""
                                />{' '}
                              </a>
                              <a
                                className=""
                                target="_blank"
                                href="https://apps.apple.com/ng/app/gotok/id6444884980"
                                rel="noreferrer"
                              >
                                {' '}
                                <img
                                  src={AppStore}
                                  alt="Appstore download"
                                  className=""
                                />{' '}
                              </a>
                            </div>
                            <img
                              src={HowAccountSVG}
                              alt=""
                              className="c-howto__illustration c-howto__illustration--account"
                            />
                          </div>
                        </div>
                        <img
                          src={HowBuyPhone}
                          alt="steps to download"
                          className="c-howto__image c-howto__image--phone"
                        />
                      </article>
                    </div>
                    <div className="l-col l-col-6">
                      <div className="l-row" style={{ height: '100%' }}>
                        <div className="c-howto__grid">
                          <article className="c-howto__steps c-howto__steps--two">
                            <img
                              src={HowPatternTwo}
                              alt="steps to download"
                              className="c-howto__image c-howto__image--patterntwo"
                            />
                            <img
                              src={HowCardStack}
                              alt="steps to download"
                              className="c-howto__image c-howto__image--card"
                            />
                            <div className="c-howto__step">
                              {/* <span>2</span> */}
                              <div>
                                <h4>Create a USD card</h4>
                                <p>
                                  Create and customize your card by choosing the
                                  variant that fits your need{' '}
                                </p>
                                <img
                                  src={HowCardSVG}
                                  alt=""
                                  className="c-howto__illustration"
                                />
                              </div>
                            </div>
                          </article>
                          <article className="c-howto__steps c-howto__steps--three">
                            <img
                              src={HowPatternThree}
                              alt="steps to download"
                              className="c-howto__image c-howto__image--patternthree"
                            />
                            <img
                              src={HowCardStackGlow}
                              alt="steps to download"
                              className="c-howto__image c-howto__image--glow"
                            />
                            <div className="c-howto__step">
                              {/* <span>3</span> */}
                              <div>
                                <h4>Make payments online</h4>
                                <p>
                                  Load up your card and start making payment
                                </p>
                                <img
                                  src={HowBuySVG}
                                  alt=""
                                  className="c-howto__illustration"
                                />
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div />
              </div>
            </div>
          </div>
        </section>

        <section className="c-testimonials">
          <div className="l-wrapper">
            <header>
              <h4>Don't just take our word for it</h4>
              <p>Here is what our users think</p>
            </header>
            <div className="c-testimonials__grid">
              <div className="c-testimonials__testimonial wide">
                <a
                  href="https://twitter.com/Nenyenwa0001/status/1608899589796679681?s=20&t=NLzNSPIhMEKOVBkRh4GU6w"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1640415684021698560/WBRPoyWE_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>Nenyenwa 💎 💎 💎</h5>
                      <p>@Nenyenwa0001</p>
                    </span>
                  </header>
                  <p>
                    Just when our dear country gave us rules on how to manage
                    our funds thereby making transactions so difficult with
                    their new rules on withdrawals, @gotokhq made things much
                    easier. Don’t dull
                  </p>
                </a>
              </div>
              <div className="c-testimonials__testimonial tall">
                <a
                  href="https://twitter.com/EtudorM/status/1609111060321112064?s=20&t=NLzNSPIhMEKOVBkRh4GU6w"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1646188388440064010/8wUv2jFh_x96.png"
                      alt="twiter profile"
                    />
                    <span>
                      <h5>𝚃𝚞𝚍𝚘𝚛🔶𝙺𝚛𝚢𝚙𝚝™️</h5>
                      <p>@EtudorM</p>
                    </span>
                  </header>
                  <p>
                    I have been able to pay for my twitter blue subscription, my
                    discord nitro and bought some courses on @udemy using
                    @gotokhq virtual card and I’m bullish on what they are
                    building! More features to come 2023
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial big">
                <a
                  href="https://twitter.com/CWEmbassy/status/1608799056595419137?s=20&t=NLzNSPIhMEKOVBkRh4GU6w"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1646786376652709888/pQ7Z7grR_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>Crypto Wealth Embassy🔶⬜️</h5>
                      <p>@CWEmbassy</p>
                    </span>
                  </header>
                  <p>
                    Don’t be scared to try out new things !!! So I wanted to be
                    sure that I could withdraw my funds in my @gotokhq card back
                    into my #Binance, and I must say it was soo fast and easy
                    !!!!!
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial">
                <a
                  href="https://twitter.com/0xtega_/status/1608894336623869952?s=20&t=NLzNSPIhMEKOVBkRh4GU6w"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1609993854400299008/agvNhLHw_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>0xTega.eth</h5>
                      <p>@0xtega_</p>
                    </span>
                  </header>
                  <p>
                    This is where @gotokhq comes 😎😎 You need no worry about
                    making international payments with any more @gotokhq gat you
                    covered
                    https://twitter.com/Fosudo/status/1608534393215406080
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial">
                <a
                  href="https://twitter.com/ace_taze/status/1610779115468079106?s=46&t=YnDf4n0kdwuboUQPWTs_jg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1623461797087924224/iq_dEPCu_x96.jpg"
                      alt=""
                    />
                    <span>
                      <h5>𝚃𝚊𝚣𝚎🩺📊</h5>
                      <p>@ace_taze</p>
                    </span>
                  </header>
                  <p>
                    @binance and @gotokhq literally blew my mind now. For months
                    I have been looking for a way to be making online payments
                    since most Nigerian cards cannot and it made me unable to
                    renew all my subscriptions. It was very sad
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial">
                <a
                  href="https://twitter.com/TradewithTwiz/status/1615671312399355905"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1618357967715278855/h5m3Fb4B_x96.jpg"
                      alt=""
                    />
                    <span>
                      <h5>Trader Twiz🧠🪔</h5>
                      <p>@TradewithTwiz</p>
                    </span>
                  </header>
                  <p>
                    @gotokhq @phantom I love the solution you guys are building
                    and I’m definitely sharing the goodnews to everyone around
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial">
                <a
                  href="https://twitter.com/pheyiyex/status/1615018187283910661"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1645425133383741440/cYvd_zcB_x96.jpg"
                      alt=""
                    />
                    <span>
                      <h5>Feyi CMADE.lens//(💙,🧡) ⬜.bit</h5>
                      <p>@pheyiyex</p>
                    </span>
                  </header>
                  <p>@gotokhq Keep. Improving love your app.</p>
                </a>
              </div>

              <div className="c-testimonials__testimonial">
                <a
                  href="https://twitter.com/ace_taze/status/1613820821453111297"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1623461797087924224/iq_dEPCu_x96.jpg"
                      alt=""
                    />
                    <span>
                      <h5>𝚃𝚊𝚣𝚎🩺📊</h5>
                      <p>@ace_taze</p>
                    </span>
                  </header>
                  <p>@gotokhq @heliuslabs @hey_wallet This is amazinggggg🔥</p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/ruthezimoha/status/1613844271060205568"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1542900175190081536/Akr8rOGA_x96.jpg"
                      alt=""
                    />
                    <span>
                      <h5>Ruth</h5>
                      <p>@ruthezimoha</p>
                    </span>
                  </header>
                  <p>@gotokhq Gotok is definitely providing solutions</p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/AisosaUwaifo/status/1611122320931946496"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1633531434466959361/yKYcpAc6_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>WAisoski❓</h5>
                      <p>@AisosaUwaifo</p>
                    </span>
                  </header>
                  <p>
                    @gotokhq Love your app NGL saw a twitter ads about it
                    yesterday and started using it right away even used my card
                    same yesterday for an online shopping, I love it
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/kenpachi_89/status/1608800574253981696"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1637987821733437441/fpmi4IyF_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>tony_accardo</h5>
                      <p>@kenpachi_89</p>
                    </span>
                  </header>
                  <p>
                    @CWEmbassy @gotokhq Love the card use for Apple Music and
                    Amazon works well
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/Valenti21188631/status/1603106315093557249"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1611854279932788741/5KprvTa9_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>Valentine</h5>
                      <p>@Valenti21188631</p>
                    </span>
                  </header>
                  <p>
                    I'm glad using @gotokhq for my online shopping transaction
                    on Aliexpress, fast and reliable to use. All thank to
                    @EtudorM for the awareness and encouragement.
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/Cosmas91/status/1607429135034368001"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1652812955820478465/skoTRLDj_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>IR🌎</h5>
                      <p>@Investor_ruthh</p>
                    </span>
                  </header>
                  <p>
                    his referal scheme give you the opportunity to earn 25%
                    referal bonus in usdc of every transaction fee
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/tech_emmanuel1/status/1599690180604342272"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1517370506344865792/Q1K5uf7j_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>Emmanuel 👽 </h5>
                      <p>@tech_emmanuel1</p>
                    </span>
                  </header>
                  <p>
                    @Investor_ruthh @gotokhq This is actually the best of it's
                    kind, stress free 💥👌🏻
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/JacobGabrielI/status/1599892852435144704"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1552043623788806144/Ny683jj8_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>Jacob Gabriel </h5>
                      <p>@JacobGabrielI</p>
                    </span>
                  </header>
                  <p>
                    @Investor_ruthh @gotokhq So many features in this app. I
                    love this. 🔥🔥
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="c-faq">
          <div className="l-wrapper">
            <div className="l-row">
              <div className="l-col-12">
                <div>
                  <header className="c-faq__header">
                    <h3>Frequently asked questions.</h3>
                    <p>Some of most frequently asked questions.</p>
                  </header>
                  <div className="c-faq__widget">
                    <details>
                      <summary>How does Gotok work</summary>{' '}
                      <p>
                        Gotok is the easiest and smartest way to make
                        international payments online, create virtual dollar
                        cards and fund with local bank transfer
                      </p>
                    </details>
                    <details>
                      <summary>How many virtual cards can i create</summary>
                      <p>
                        Gotok user can create up to 5 virtual cards at a time
                      </p>
                    </details>
                    <details>
                      <summary>What variant of cards can be created</summary>
                      <p>
                        Mastercard and visa virtual dollar cards can be created
                      </p>
                    </details>
                    <details>
                      <summary>what are the Spending limits</summary>
                      <p>
                        Verified Gotok users have a daily limit of $5000 and
                        monthly limit of $100,000
                      </p>
                    </details>
                    <details>
                      <summary>Are my funds safe?</summary>{' '}
                      <p>
                        Funds in your gotok cards are securely encrypted and
                        protected.
                      </p>
                    </details>
                    <details>
                      <summary>
                        Can you use this for normal subscriptions and intl
                        payments? Like HBO, Netflix, Apple Music etc and
                        international payments
                      </summary>
                      <p>
                        {' '}
                        Yes, Gotok card can be used for international payments
                        or subscriptions
                      </p>
                    </details>
                    <details>
                      <summary>
                        Is it possible to withdraw after funding?
                      </summary>
                      <p>
                        Yes, if for any reason you decide to withdraw after
                        funding, there is an option for that
                      </p>
                    </details>
                    {/* <details>
                      <summary>
                        At what rate was the dollar charged to naira?
                      </summary>
                      <p>
                        Gotok is not responsible for the rates, the exchange
                        rate is determined by the market rate
                      </p>
                    </details> */}
                    <details>
                      <summary>what are the charges, fees</summary>
                      <p>Gotok charges 1% Virtual card reload fee</p>
                    </details>
                    <details>
                      <summary>Which currencies are available to use</summary>
                      <p>
                        Gotok offers only USD cards at the moment. stay tuned
                        for updates in the future
                      </p>
                    </details>
                    <details>
                      <summary>What you can't use your card for</summary>
                      <ul>
                        <li>Money Transfers</li>
                        <li>Travel Related Arrangement Services</li>
                        <li>Outbound Telemarketing Merchants</li>
                        <li>Non-Financial Institutions/Cryptocurrency</li>
                        <li>Security Brokers/Dealers</li>
                        <li>Dating and Escort Services</li>
                        <li>Massage Parlors</li>
                        <li>Government-owned Lotteries</li>
                        <li>
                          Government-licensed Online Casinos (Online Gambling)
                        </li>
                        <li>Government-licensed Horse/Dog Racing</li>
                        <li>Betting and Online Gambling</li>
                      </ul>
                    </details>
                    <details>
                      <summary>Can i earn on Gotok</summary>
                      <ul>
                        <li>Step 1: Share your referral link with friends.</li>
                        <li>
                          Step 2: Once your friend registers for a Gotok
                          account, and creates a card, your friend will receive
                          75% instant cashback on the fee
                        </li>
                      </ul>
                    </details>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="c-cta">
          <div className="l-wrapper">
            <div className="l-row">
              <div className="l-col-12">
                <div className="c-cta__text">
                  <img
                    src={CtaCoinSVG}
                    alt=""
                    className="c-cta__illustration c-cta__illustration--coin"
                  />
                  <img
                    src={CtaGlitzSVG}
                    alt=""
                    className="c-cta__illustration c-cta__illustration--glitz"
                  />
                  <img
                    src={CtaCardsPNG}
                    alt=""
                    className="c-cta__illustration c-cta__illustration--cards"
                  />
                  <h3>
                    Download the Gotok app for the easier way to make
                    international payment online
                  </h3>
                  <a
                    href="https://web.gotok.app/"
                    className="c-cta__link"
                    alt="link to download gotok"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </main>
    // </LocomotiveScrollProvider>
  );
}

export default Landing;
