import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from '../images/logo-alt.svg';
import './header.scss';

const LandingHeaderLinks = () => (
  <ul>
    <li className="c-nav__link hide-mobile">
      <Link to="/">Home</Link>
    </li>
    <li className="c-nav__link hide-mobile">
      <Link to="/privacy">Privacy</Link>
    </li>
    <li className="c-nav__link hide-mobile">
      <Link to="/terms">Terms</Link>
    </li>
    <li className="c-nav__link hide-mobile">
      <Link to="/aml">AML Policy</Link>
    </li>
    <li className="c-nav__link">
      <a
        className="c-manifest__button"
        target="_blank"
        href="https://web.gotok.app/"
        rel="noreferrer"
      >
        {' '}
        Launch App{' '}
      </a>
    </li>
  </ul>
);

export default function Header({ children }) {
  return (
    <header className="c-header">
      <div className="l-wrapper">
        <div className="l-row">
          <nav className="c-nav">
            <div className="c-nav__logo">
              <a href="/">
                <img src={Logo} alt="Gotok Pay" />
              </a>
            </div>

            <menu className="c-nav__menu">{children}</menu>
          </nav>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export { LandingHeaderLinks };
