// import { gsap } from 'gsap';
// import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import GiftBoxSVG from './giftboxsvg';
import CoinSVG from './coinsvg';
import SunBurstIMG from './images/burst.svg';
import Header, { LandingHeaderLinks } from '../../components/header/header';
import Footer from '../../components/footer/footer';
import './referral.scss';

function Referral() {
  // const ref = useRef(null);

  // const options = {
  //   smooth: true,
  // };

  return (
    <main className="c-referral">
      <Header>
        <LandingHeaderLinks />
      </Header>
      {/* <div className="c-noise" /> */}
      <section className="" data-scroll-section>
        <article className="c-referral__hero">
          {/* <svg width="100%" height="100%" style={{ position: 'absolute' }}>
            <filter id="noiseFilter">
              <feTurbulence
                type="fractalNoise"
                baseFrequency="6.29"
                numOctaves="6"
                stitchTiles="stitch"
              />
            </filter>
            <rect width="100%" height="100%" filter="url(#noiseFilter)" />
          </svg> */}
          <img src={SunBurstIMG} alt="" className="c-referral__sunburst" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="http://svgjs.dev/svgjs"
            height="100%"
            width="100%"
            viewBox="0 0 2000 2000"
            preserveAspectRatio="none"
            opacity="1"
            style={{ position: 'absolute' }}
            className="c-referral__noise"
          >
            <defs>
              <filter
                id="nnnoise-filter"
                x="-20%"
                y="-20%"
                width="140%"
                height="140%"
                filterUnits="objectBoundingBox"
                primitiveUnits="userSpaceOnUse"
                colorInterpolationFilters="linearRGB"
              >
                <feTurbulence
                  type="fractalNoise"
                  baseFrequency="0.101"
                  numOctaves="4"
                  seed="15"
                  stitchTiles="stitch"
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                  result="turbulence"
                />
                <feSpecularLighting
                  surfaceScale="14"
                  specularConstant="1"
                  specularExponent="20"
                  lightingColor="#fdd78b"
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                  in="turbulence"
                  result="specularLighting"
                >
                  <feDistantLight azimuth="3" elevation="101" />
                </feSpecularLighting>
              </filter>
            </defs>
            <rect width="100%" height="100%" fill="transparent" />
            <rect
              width="100%"
              height="100%"
              fill="#fdd78b"
              filter="url(#nnnoise-filter)"
            />
          </svg>
          <CoinSVG />
          <GiftBoxSVG />
          <div className="l-wrapper">
            <div className="l-row">
              <div className="l-col-10" style={{ zIndex: 10 }}>
                <h5>How to Use Gotok Referral</h5>
                {/* <p>Refer a friend and</p> */}
                <h3>
                  Refer a friend <br /> <span> Get </span> <span> 25%</span>
                  {/* <span> Cashback</span> */}
                  <svg
                    width="60"
                    height="101"
                    viewBox="0 0 107 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      position: 'absolute',
                      right: '240px',
                      bottom: '-15px',
                    }}
                  >
                    <g className="c-referral__glitz c-referral__glitz-one">
                      {' '}
                      <path
                        d="M46.0542 86.7959C43.4668 85.9803 47.2566 71.1161 46.0037 68.7099C44.7508 66.3036 30.4001 60.8847 31.2157 58.2973C32.0312 55.7099 46.8954 59.4997 49.3017 58.2468C51.708 56.9939 57.1268 42.6432 59.7143 43.4588C62.3017 44.2743 58.5119 59.1385 59.7648 61.5448C61.0177 63.9511 75.3684 69.37 74.5528 71.9574C73.7372 74.5448 58.873 70.755 56.4668 72.0079C54.0605 73.2608 48.6416 87.6115 46.0542 86.7959Z"
                        fill="#6F26FA"
                      />
                    </g>

                    <path
                      d="M78.0257 40.3184C85.9057 44.2684 86.2357 45.2284 82.3757 56.0684C82.3557 56.1184 82.4457 56.1484 82.4657 56.1084C87.2757 45.6584 88.1857 45.1984 96.5857 47.8784C96.6357 47.8984 96.6657 47.8284 96.6157 47.8084C88.7357 43.8584 88.4057 42.8984 92.2657 32.0584C92.2857 32.0084 92.1957 31.9784 92.1757 32.0184C87.3657 42.4684 86.4557 42.9284 78.0557 40.2484C78.0057 40.2284 77.9757 40.2984 78.0257 40.3184V40.3184Z"
                      fill="#FFCB64"
                      className="c-referral__glitz c-referral__glitz-two"
                    />
                    <path
                      d="M35.5223 34.8271C34.2194 34.6608 34.748 27.2522 33.9442 26.2132C33.1404 25.1743 25.8365 23.8252 26.0028 22.5222C26.1692 21.2192 33.5777 21.7478 34.6166 20.9441C35.6556 20.1403 37.0046 12.8364 38.3076 13.0027C39.6107 13.169 39.082 20.5776 39.8858 21.6165C40.6896 22.6555 47.9935 24.0045 47.8272 25.3075C47.661 26.6105 40.2523 26.0819 39.2134 26.8857C38.1744 27.6895 36.8254 34.9934 35.5223 34.8271V34.8271Z"
                      fill="#6F26FA"
                      className="c-referral__glitz c-referral__glitz-three"
                    />
                  </svg>
                </h3>
                <p>
                  <span>You get 25% referral bonus </span> on all their
                  transaction fees for 6 months, and they earn 75% instant
                  cashback on their first transaction fee
                </p>
              </div>
            </div>
          </div>
        </article>
        <article className="c-legal__content">
          <div className="l-wrapper">
            <div className="l-row">
              <div className="l-col-9">
                <div className="c-legal__text">
                  <h2>How can I earn referral rewards?</h2>
                  <p>Step 1: Share your referral link with friends.</p>
                  <p>
                    Step 2: Once your friend registers for a Gotok account, and
                    creates a card, your friend will receive 75% instant
                    cashback on the fee
                  </p>
                </div>

                <div className="c-legal__text">
                  <h2>How do I claim my referral rewards?</h2>
                  <p>
                    To be eligible for referral rewards, you must verify your
                    phone number on Gotok
                  </p>
                  <p>For example:</p>
                  <p>User A invites User B via Referral link.</p>
                  <p>User B verifies Phone Verification and creates a card</p>
                  <p>
                    User A has not completed Phone Verification, so ineligible
                    for the reward.
                  </p>
                  <p>
                    Once User A completes Phone Verification, User A will also
                    be eligible for the reward.
                  </p>
                </div>

                <div className="c-legal__text">
                  <h2>How can I get more rewards?</h2>
                  <p>
                    You can earn more cashback by inviting more friends to join
                    Gotok and create their first card
                  </p>
                </div>

                {/* <div className="c-legal__text">
                    <h2>How many Trading Fee Rebate Vouchers can I get?</h2>
                    <p>
                      There are no restrictions on the number of Trading Fee
                      Rebate Vouchers you can earn.
                    </p>
                  </div> */}

                <div className="c-legal__text">
                  <h2>How can I use the Reward</h2>
                  <p>
                    You can spend your reward balance by using it to create or
                    fund a card
                  </p>
                  {/* <p>
                      For more details on how to use Trading Fee Rebate
                      Vouchers, please refer to Trading Fee Rebate Voucher Terms
                      and Conditions.
                    </p> */}
                </div>

                <div className="c-legal__text">
                  <h2>Terms and Conditions</h2>
                  <ol>
                    <li>
                      Referral bonus applies to only card funding transaction
                      fees
                    </li>
                    {/* <li>
                        Referrals bonuses are earned for all transaction fees,
                        after a user joins with your referral code, for a period
                        of six months.
                      </li> */}
                    <li>
                      Gotok reserves the right to cancel or amend any Activity
                      or Activity Rules at its sole discretion.
                    </li>
                    <li>
                      All participants must strictly abide by the{' '}
                      <Link to="/terms">Gotok Terms of Service.</Link> Any
                      breach of the Gotok Terms of Use will disqualify the user
                      from earning referral rewards.
                    </li>
                    <li>
                      Gotok has the sole discretion to decide and determine
                      whether a user will be entitled to earn any referral
                      reward and reserves the right to amend these Terms &
                      Conditions from time to time. If you have any questions,
                      please contact customer service.
                    </li>
                    <li>
                      Gotok reserves the right to disqualify users from
                      participating in the referral program and revoke any
                      reward from users who engage in dishonest or abusive
                      activities to earn rewards. This includes bulk-account
                      registrations to obtain additional bonuses, usage of
                      “phishing” websites containing referral links, posting
                      referral links as advertisements on search engines, and
                      any other activity in connection with unlawful,
                      fraudulent, or harmful purposes.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
      <Footer />
    </main>
  );
}

export default Referral;
